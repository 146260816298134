import { deleteWeirdCharacters } from "utils/collaborators";
import { UPLOADING, WAITING_UPLOAD, SUCCESSFULLY_UPLOADED } from "variables/uploads.variables";

export const chunkSize = 1048576 * 2;

export const createUpload = (fileId, file, existedInFuga) => {
  return {
    fileSize: file.size,
    fileId,
    fugaUuid: "",
    totalChunks: existedInFuga ? 1 : 0,
    totalChunksUploaded: existedInFuga ? 1 : 0,
    startChunk: 0,
    endChunk: chunkSize,
    fileToUpload: file,
    uploadedBytes: 0,
    status: existedInFuga ? SUCCESSFULLY_UPLOADED : WAITING_UPLOAD
  };
}

export const startUploadState = (fileName, fileId, fugaUuid, fileObject, bytesRemaining, uploadedBytes, endingChunk) => {
  return {
    fileSize: fileObject.size,
    fileId,
    fugaUuid,
    totalChunks: Math.ceil(bytesRemaining / chunkSize),
    totalChunksUploaded: 0,
    startChunk: uploadedBytes,
    fileToUpload: fileObject,
    uploadedBytes,
    endChunk: endingChunk === fileObject.size ? endingChunk + 1 : endingChunk,
    fileName: deleteWeirdCharacters(fileName),
    status: UPLOADING
  }
}

export const fileStateAddOneChunk = (oldFileState, endingChunk, fileSize) => {
  return {
    ...oldFileState,
    totalChunksUploaded: oldFileState.totalChunksUploaded + 1,
    startChunk: oldFileState.endChunk,
    endChunk: endingChunk === fileSize ? endingChunk + 1 : endingChunk,
    uploadedBytes: oldFileState.endChunk,
  }
}