import React, { useEffect, useState } from 'react';
import {
  DialogTitle, DialogContentText, DialogContent, DialogActions,
  Dialog, Button, Grid
} from '@mui/material/';

import { Image as ReactImage } from 'mui-image';
import { to, toWithOutError } from 'utils';
import ImageInput from 'components/Input/ImageInput';
import { manageAddImageToStorage } from 'services/StorageServices';
import { useDispatch, useSelector } from 'react-redux';
import { userDataUpdateRedux } from 'redux/actions/UserDataActions';
import { paymentsPayUpdated } from 'redux/actions/PaymentsActions';
import { SUB_ORDER_NEED_CHECK, SUB_ORDER_PAYED, SUB_ORDER_REJECTED_PROOF } from 'variables/financial';
import { useNavigate } from 'react-router-dom';
import { getPendgingCheckPaymentFromUserFS } from 'services/FirestoreServices';
import { editUserRedux } from 'redux/actions/UsersActions';
import { getUserById } from 'utils/users.utils';
import { getNewUserActiveInfoFromPayment, getNewUserNeedCheckInfoFromPayment } from 'factory/users.factory';
import { dangerColor, mainBlue } from 'variables/colors';
import { discountsUpdatePostPay } from 'redux/actions/DiscountsActions';
import { USER_REJECTED_PROOF } from 'variables/user.variables';
import ProgressButton from 'components/CustomButtons/ProgressButton';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

const PayProofDialog = ({ open, setOpen, showOrLoad, userId, payment }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(store => store.userData);
  const usersFromStore = useSelector(store => store.users);
  const user = getUserById(usersFromStore, userId);

  const actualPayment = useSelector(store => store.payments.actualPayment);
  const isLoadingProof = showOrLoad === "load";

  const [messageForProof, setMessageForProof] = useState("")
  const [progress, setProgress] = useState(0);
  const [imagenUrl, setImagenUrl] = useState("");
  const [fileType, setFileType] = useState("image");
  const [resolution, setResolution] = useState({ width: 0, height: 0 });
  const [paymentSearched, setPaymentSearched] = useState("");
  const [paymentProofNotFound, setPaymentProofNotFound] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const onClickAddImage = async (event) => {
    setImagenUrl("");
    setMessageForProof("");
    let file = event.target.files[0];
    let img = new Image();
    if (file.type.includes("image")) {
      img.src = window.URL.createObjectURL(file);
      img.onload = async () => {
        setResolution({ width: img.width, height: img.height });
      }
    }
    let saveAsThumbnail = false;
    let [errorAddingFile, urlAndFile] = await to(manageAddImageToStorage(saveAsThumbnail, file, actualPayment.id,
      'paymentsProofs', MAX_FILE_SIZE, setMessageForProof, setProgress, userData.email));
    if (errorAddingFile) {
      setMessageForProof("Ha ocurrido un error, por favor, intente nuevamente. ");
      return;
    }
    setImagenUrl(urlAndFile.url);

    let newPaymentInfo = {
      status: SUB_ORDER_NEED_CHECK,
      paymentProof: { url: urlAndFile.url, type: file.type, width: img.width, height: img.height },
      lastPaymentDate: new Date().getTime()
    };

    let updatePaymentDoc = await toWithOutError(dispatch(paymentsPayUpdated({ ...actualPayment, ...newPaymentInfo })));
    if (updatePaymentDoc === "ERROR") return "ERROR";
    let fromPaymentAction = true;
    let updateUserDoc = dispatch(userDataUpdateRedux(getNewUserNeedCheckInfoFromPayment(userData, actualPayment), fromPaymentAction));
    if (updateUserDoc === "ERROR") return "ERROR";
    setFileType(file.type);
  }

  const handleOk = async () => {
    if (isLoadingProof)
      navigate("/admin/subscription");
    else {
      setOpenLoading(true); setButtonState('loading');
      let newUserInfo = getNewUserActiveInfoFromPayment(user, paymentSearched);
      let fromPaymentAction = true;
      let editResult = await toWithOutError(dispatch(editUserRedux(user, newUserInfo, fromPaymentAction, false, false, false)));
      if (editResult === "ERROR") { setOpenLoading(false); setButtonState('error'); return; }
      let updatePaymentDoc = await toWithOutError(dispatch(paymentsPayUpdated({ ...paymentSearched, status: SUB_ORDER_PAYED })));
      if (updatePaymentDoc === "ERROR") { setOpenLoading(false); setButtonState('error'); return; }

      // Aca seteo el discount en vacio para que lo tome del PAGO.
      let emptyDiscount = "";
      let updateDiscountDoc = await toWithOutError(dispatch(discountsUpdatePostPay(emptyDiscount, paymentSearched)));
      if (updateDiscountDoc === "ERROR" || updateDiscountDoc === "NOT_FOUND") console.log("ERROR: ", updateDiscountDoc);
      setOpenLoading(false);
    }
    setOpen(false);
  }

  const handleClose = () => setOpen(false);

  useEffect(() => {
    let getUserPendingPay = async () => {
      let pendingCheckPayment = await toWithOutError(getPendgingCheckPaymentFromUserFS(userId, dispatch));
      if (pendingCheckPayment === "EMPTY") { setPaymentProofNotFound(true); return; }
      setImagenUrl(pendingCheckPayment.paymentProof?.url || "");
      setFileType(pendingCheckPayment.paymentProof?.type || "");
      setResolution({ width: pendingCheckPayment.paymentProof.width, height: pendingCheckPayment.paymentProof.height });
      setPaymentSearched(pendingCheckPayment);
    }

    if (!isLoadingProof && open && userId) {
      getUserPendingPay();
    }
  }, [userId])

  useEffect(() => {
    if (!isLoadingProof && open && payment) {
      setImagenUrl(payment.paymentProof?.url || "");
      setFileType(payment.paymentProof?.type || "");
      setResolution({ width: payment.paymentProof.width, height: payment.paymentProof.height });
      setPaymentSearched(payment);
    }
  }, [payment])

  const handleRejectProof = async () => {
    let fromPaymentAction = true;
    let editResult = await toWithOutError(dispatch(editUserRedux(user, { userStatus: USER_REJECTED_PROOF }, fromPaymentAction, false, false, false)));
    if (editResult === "ERROR") { setOpen(false); return; }
    let updatePaymentDoc = await toWithOutError(dispatch(paymentsPayUpdated({ ...paymentSearched, status: SUB_ORDER_REJECTED_PROOF })));
    if (updatePaymentDoc === "ERROR") { setOpen(false); return; }
    setOpen(false);
  }

  const sxImage = { width: resolution.width, height: resolution.height, borderRadius: 5 };
  const buttonRejectSx = { backgroundColor: dangerColor, '&:hover': { backgroundColor: dangerColor[2] } }

  return (

    <Dialog open={open || false} onClose={handleClose} maxWidth='sm' fullWidth={true}>

      <DialogTitle sx={{ textAlign: 'center', fontSize: 25 }}>
        {isLoadingProof ? "Carga el comprobante de Pago" : "Comprobante de Pago"}
      </DialogTitle>
      <DialogContent>

        <DialogContentText paddingBottom={1}>
          Puede suceder que el usuario no haya necesitado cargar ningun comprobante. Por ejemplo, si pagó
          mendiante la pasarela de Mercado Pago. En caso de pagar por MP, se puede verificar el pago con
          el <b>MP ID </b>
        </DialogContentText>

        {paymentProofNotFound && <DialogContentText>
          <b>No hemos encontrado el comprobante del último pago.</b>
        </DialogContentText>}

        {isLoadingProof && <DialogContentText>
          <b>Por favor, carga el comprobante de pago.</b>
          <br />
          <b>Puede tener los siguientes formatos: PDF, PNG, JPG, JPEG.</b>
          <br />
          <b>Pero preferentemente solicitamos: PNG, JPG, JPEG.</b>
          <br />
          <b>El archivo no debe ser mayor a 10MB</b>
        </DialogContentText>}

        {imagenUrl && (
          <Grid sx={{ padding: "1em", textAlign: 'center' }}>
            <a href={imagenUrl} target="_blank" rel="noreferrer">
              Archivo cargado correctamente, click para descargar.
            </a>
          </Grid>
        )}

        {isLoadingProof
          ? <Grid sx={{ textAlign: 'center', mt: 1 }}>
            <ImageInput sxImage={sxImage} key="pay-proof" imagenUrl={imagenUrl} onClickAddImage={onClickAddImage}
              textButton={imagenUrl === "" ? "Cargar Comprobante" : "Cambiar Comprobante"} progress={progress} message={messageForProof}
              helperText={""} fileTypesAccept={".pdf,.jpg,.jpeg,.png"} fileType={fileType} noFab={false}
            />
          </Grid>
          : <>
            {(imagenUrl && fileType.includes('image')) && (
              <Grid >
                <ReactImage
                  style={sxImage}
                  alt="album-image-show"
                  duration={30}
                  src={imagenUrl}
                />
              </Grid>
            )}
          </>}

      </DialogContent>

      <DialogActions padding={1}>
        <Button onClick={handleClose}>Volver</Button>
        {!isLoadingProof && <Button variant="contained" sx={buttonRejectSx} onClick={handleRejectProof}>Rechazar</Button>}
        <ProgressButton
          textButton={!isLoadingProof ? "Activar Usuario" : "Ok"}
          loading={openLoading}
          buttonState={buttonState}
          onClickHandler={handleOk}
          buttonSx={{ backgroundColor: mainBlue, ml: 1 }}
          noFab={true}
          buttonFullWidth={false} />

        {/* <LoadingButton
          textButton={!isLoadingProof ? "Activar Usuario" : "Ok"}
          loading={openLoading}
          buttonState={buttonState}
          onClickHandler={handleOk}
          buttonSx={{ backgroundColor: mainBlue, ml: 1 }}
        /> */}
      </DialogActions>

    </Dialog>
  );
};

export default PayProofDialog;
