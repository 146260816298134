import { formatEquivalence, getOurFormatByCantOfTracks, checkVersionField, wipeOutForbiddenVersions } from 'utils/albums.utils';
import { v4 as uuidv4 } from 'uuid';
import { getAllArtistsOfTrack, ifNoPrimaryChangeIt } from '../utils/artists.utils';
import { appVersion } from '../variables/app.variables';
import { fromCollaboratorsToContributors } from 'utils/collaborators';

// Lo uso tanto para crear como para editar.
export const createArtistModel = (dataArtist, editing) => {
  let rawDataArtist = {};
  if (dataArtist.name) rawDataArtist.name = dataArtist.name;
  if (!editing && dataArtist.id) rawDataArtist.proprietary_id = dataArtist.id;
  if (dataArtist.biography) rawDataArtist.biography = dataArtist.biography;
  if (dataArtist.spotify_uri !== undefined) rawDataArtist.spotify_uri = dataArtist.spotify_uri || "";
  if (dataArtist.apple_id !== undefined) rawDataArtist.apple_id = dataArtist.apple_id || "";
  // if (dataArtist.photo) formDataArtist.append("photo", dataArtist.photo);
  return rawDataArtist;
}

const checkIfConsumerDateIsLowerThanOriginal = (newReleaseDate, originalFugaReleaseDate) => {
  if (!originalFugaReleaseDate) return true;
  if (originalFugaReleaseDate.slice(0, 10) < newReleaseDate) return true;
  return false;
}

export const createEditAlbumModel = (albumDataToEdit, albumFugaData) => {
  let editAlbumDataFuga = {};
  let saleAndReleaseDate = albumDataToEdit.year ? `${albumDataToEdit.year}-${albumDataToEdit.month}-${albumDataToEdit.dayOfMonth}` : "";
  let keys = Object.keys(albumDataToEdit);
  keys.forEach(key => {
    if (key === "title") editAlbumDataFuga.name = albumDataToEdit.title;
    if (key === "version") editAlbumDataFuga.release_version = albumDataToEdit.version;
    if (key === "year") {
      if (checkIfConsumerDateIsLowerThanOriginal(saleAndReleaseDate, albumFugaData.original_release_date)) {
        editAlbumDataFuga.original_release_date = saleAndReleaseDate;
      }
      editAlbumDataFuga.consumer_release_date = saleAndReleaseDate;
    }
    if (key === "explicit") editAlbumDataFuga.parental_advisory = albumDataToEdit.explicit;
  })
  return editAlbumDataFuga;
}

export const createCoverModel = (coverFile, coverFugaId) => {
  let formDataCover = new FormData();
  formDataCover.append("type", "image_cover_art");
  formDataCover.append("cover", coverFile);
  formDataCover.append("id", coverFugaId);
  return formDataCover;
}

export const createAlbumModel = (dataAlbum, explicit, myTracks, artistsInvitedStore, deliverToApple) => {
  let formDataAlbum = new FormData();

  dataAlbum.title = myTracks.length === 1 ? myTracks[0].title : dataAlbum.title;
  dataAlbum.format = deliverToApple ? getOurFormatByCantOfTracks(myTracks.length, deliverToApple) : dataAlbum.format || getOurFormatByCantOfTracks(myTracks.length);
  dataAlbum.version = checkVersionField(dataAlbum.version);

  if (deliverToApple && myTracks.length === 1 && dataAlbum.version) {
    myTracks[0].version = dataAlbum.version;
  }

  let saleAndReleaseDate = `${dataAlbum.year}-${dataAlbum.month}-${dataAlbum.dayOfMonth}`;
  let saleAndReleaseAsDate = new Date(saleAndReleaseDate);
  if (saleAndReleaseAsDate.getTime() < new Date().getTime()) saleAndReleaseDate = new Date().toISOString().slice(0, 10);
  let originalReleaseDate = dataAlbum.oldRelease ? `${dataAlbum.originalYear}-${dataAlbum.originalMonth}-${dataAlbum.originalDayOfMonth}` : saleAndReleaseDate;
  let preOrderDate = `${dataAlbum.preOrderYear}-${dataAlbum.preOrderMonth}-${dataAlbum.preOrderDayOfMonth}`;

  let artistsArray = [{ primary: true, id: dataAlbum.artistFugaId }];
  dataAlbum.allOtherArtists.forEach(otherArtist => {
    if (otherArtist.fugaId) artistsArray.push({ primary: otherArtist.primary || false, id: otherArtist.fugaId });
    else {
      let artistFromStore = artistsInvitedStore.find(artistInvited => artistInvited.name === otherArtist.name);
      if (artistFromStore && artistFromStore.fugaId) artistsArray.push({ primary: otherArtist.primary || false, id: artistFromStore.fugaId });
    }
  });

  formDataAlbum.append('releaseDayjsFormat', dataAlbum.releaseDayjsFormat)
  formDataAlbum.append("name", dataAlbum.title);
  formDataAlbum.append("label", dataAlbum.labelFugaId);
  formDataAlbum.append("language", dataAlbum.languageId);
  formDataAlbum.append("catalog_number", uuidv4());
  formDataAlbum.append("release_format_type", formatEquivalence[dataAlbum.format]);
  formDataAlbum.append("c_line_text", dataAlbum.c_line);
  formDataAlbum.append("c_line_year", dataAlbum.c_year);
  formDataAlbum.append("p_line_text", dataAlbum.p_line);
  formDataAlbum.append("p_line_year", dataAlbum.p_year);
  formDataAlbum.append("genre", dataAlbum.genre);
  formDataAlbum.append("artists", JSON.stringify(artistsArray));
  formDataAlbum.append("consumer_release_date", saleAndReleaseDate);
  formDataAlbum.append("original_release_date", originalReleaseDate);
  formDataAlbum.append("parental_advisory", Boolean(explicit));
  if (dataAlbum.preOrderYear > 0) formDataAlbum.append("preorder_date", preOrderDate);
  if (dataAlbum.upc) formDataAlbum.append("upc", dataAlbum.upc);
  if (dataAlbum.subgenre) formDataAlbum.append("subgenre", dataAlbum.subgenre);
  if (dataAlbum.version) formDataAlbum.append("release_version", wipeOutForbiddenVersions(dataAlbum.version));

  formDataAlbum.append("extra_1", `Cantidad de Tracks total:${myTracks.length}`);
  formDataAlbum.append("extra_2", `Deliver to apple:${deliverToApple}`);
  formDataAlbum.append("extra_3", `Id en la app del lanzamiento:${dataAlbum.id}`);
  formDataAlbum.append("extra_4", `Email en la app del user:${dataAlbum.ownerEmail}`);
  formDataAlbum.append("extra_5", `Id en la app del user:${dataAlbum.ownerId}`);
  formDataAlbum.append("extra_8", `Version App:${appVersion}`);
  formDataAlbum.append("extra_7", `Id en la app del Artista:${dataAlbum.artistId}`);
  formDataAlbum.append("typeCover", "image_cover_art");
  formDataAlbum.append("cover", dataAlbum.cover);

  return formDataAlbum;
};

export const createEditTrackModel = newTrackData => {
  let formDataTrack = new FormData();

  if (newTrackData.name) formDataTrack.append("name", newTrackData.name);
  if (newTrackData.asset_version) formDataTrack.append("asset_version", newTrackData.asset_version);
  if (newTrackData.artists) {
    let formattedFugaArtists = newTrackData.artists.map(artist => { return { primary: artist.primary, id: artist.fugaId } });
    formDataTrack.append("artists", formattedFugaArtists);
  }

  return formDataTrack;
}

export const createTrackAssetModel = (dataTrack, artistInvited, artistRecentlyCreated) => {

  let artistsArrayToUpload = getAllArtistsOfTrack([...dataTrack.artists, ...dataTrack.allOtherArtists], artistInvited, artistRecentlyCreated);
  artistsArrayToUpload = ifNoPrimaryChangeIt(artistsArrayToUpload);

  let formDataTrack = new FormData();

  formDataTrack.append("asset_version", wipeOutForbiddenVersions(dataTrack.version));
  formDataTrack.append("name", dataTrack.title);
  formDataTrack.append("genre", dataTrack.genre);
  formDataTrack.append("artists", JSON.stringify(artistsArrayToUpload));
  formDataTrack.append("sequence", dataTrack.position);
  formDataTrack.append("language", dataTrack.track_language_id || "ES");
  formDataTrack.append("audio_locale", dataTrack.audio_locale_id || "ES");
  formDataTrack.append("parental_advisory", dataTrack.explicit || false);
  if (dataTrack.isrc) formDataTrack.append("isrc", dataTrack.isrc);
  if (dataTrack.subgenre) formDataTrack.append("subgenre", dataTrack.subgenre);
  if (dataTrack.lyrics) formDataTrack.lyrics("lyrics", dataTrack.lyrics);
  if (dataTrack.preOrder) {
    formDataTrack.append("allow_preorder", true);
    formDataTrack.append("available_separately", true);
    formDataTrack.append("allow_preorder_preview", dataTrack.preview);
    formDataTrack.append("preorder_only", true);
  }
  formDataTrack.append("albumId", dataTrack.albumFugaId);

  return formDataTrack;
}

export const createTrackFileModel = dataTrack => {
  let formDataTrack = new FormData();
  formDataTrack.append("track", dataTrack.track);
  formDataTrack.append("trackId", dataTrack.fugaId);
  return formDataTrack;
}

export const createTrackNewFileModel = (chunk, dataTrack) => {
  let formDataTrack = new FormData();
  formDataTrack.append("file", chunk);
  formDataTrack.append("uniqueFileId", dataTrack.uniqueFileId);
  formDataTrack.append("fileSize", dataTrack.fileSize);
  formDataTrack.append("chunkIndex", dataTrack.chunkIndex);
  formDataTrack.append("totalParts", dataTrack.totalParts);
  formDataTrack.append("fileExtension", dataTrack.fileExtension);
  formDataTrack.append("chunkSize", dataTrack.chunkSize);
  formDataTrack.append("mimeType", dataTrack.mimeType);
  formDataTrack.append("fileName", dataTrack.fileName);
  return formDataTrack;
}

export const createPersonsModel = personsArray => {
  let formDataPersons = new FormData();
  formDataPersons.append("names", JSON.stringify(personsArray));
  return formDataPersons;
}