import React, { useRef, useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { createTrackLocalRedux, editTrackAssetInAlbumRedux } from '../../redux/actions/TracksActions';
import { toWithOutError, useForceUpdate } from "utils";
import ArtistInAddTrack from '../Artists/ArtistInAddTrack';
import { cloneDeepLimited } from '../../utils';
import AddCollaboratorsForm from '../../components/Forms/AddCollaboratorsForm';
import AddOtherArtistsTrackForm from '../../components/Forms/AddOtherArtistsTrackForm';
import TextFieldWithInfo from 'components/TextField/TextFieldWithInfo';
import EditOrAddFieldsDialog from '../../components/Dialogs/EditOrAddFieldDialog';
import InfoActionDialog from '../../components/Dialogs/InfoActionDialog';
import { spotifyUriIsValid, artistsWithUniqueName } from '../../utils/artists.utils';
import { invalidCollNames, assetVersionsHelper, editTrackArtistsInfo, collaboratorsNewTrackText, invalidCollRoles } from "utils/textToShow.utils";
import { invalidCollaboratorsNames, noRequireCollRoles } from '../../utils/collaborators';
import { createOurTrackToEditFromFugaTrack } from "factory/tracks.factory";
import { getTrackFugaById } from "services/BackendCommunication";

export const EditTrackDialog = (props) => {

  let { openEditTrack, setOpenEditTrack, trackFugaData, userCreds } = props;

  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator());
  const forceUpdate = useForceUpdate();

  const currentUserId = useSelector(store => store.userData.id);

  const topElementRef = useRef(null);
  const scrollToTop = () => topElementRef.current ? topElementRef.current.scrollIntoView() : null;

  const defaultTrackData = { title: "", artists: [], version: "", allOtherArtists: [], collaborators: [] };

  const [openLoader, setOpenLoader] = useState(false);
  const [buttonState, setButtonState] = useState("none");
  const [openEditDialog, setOpenEditDialog] = useState({ open: false, title: "", subtitle: [""], values: "" });
  const [collNamesInvalid, setCollNamesInvalid] = useState(false);
  const [collRolesInvalid, setCollRolesInvalid] = useState(false);
  const [trackDataToUpdate, setTrackDataToUpdate] = useState(defaultTrackData);
  const [originalFugaTrack, setOriginalFugaTrack] = useState("");

  let albumWithOneTrack = true;

  useEffect(() => {
    const getFugaAssetAllData = async trackFugaId => {
      setOpenLoader(true);
      const trackFromFugaAllData = await toWithOutError(getTrackFugaById(trackFugaId, dispatch));
      if (trackFromFugaAllData === "NOT_EXISTS") { setButtonState("error"); setOpenLoader(false); return; };
      if (trackFromFugaAllData === "ERROR") { setButtonState("error"); setOpenLoader(false); return "ERROR"; }
      let neededDataFromFuga = createOurTrackToEditFromFugaTrack(trackFromFugaAllData);
      setOriginalFugaTrack(trackFromFugaAllData);
      setTrackDataToUpdate(neededDataFromFuga);
      setOpenLoader(false);
    }
    if (trackFugaData.id) getFugaAssetAllData(trackFugaData.id);
  }, [trackFugaData]);

  const handleCancelDialog = () => setOpenEditTrack({ open: false, trackFugaData: {} });

  const handleEditTrack = async () => {
    let editResult = await toWithOutError(dispatch(
      editTrackAssetInAlbumRedux(trackDataToUpdate, originalFugaTrack, userCreds.ownerEmail, userCreds.ownerId)));
    // handleCancelDialog();
  }

  const allFieldsValidCreateTrack = () => {
    let allArtists = [...trackDataToUpdate.artists, ...trackDataToUpdate.allOtherArtists];
    let allArtistsTrack = artistsWithUniqueName(allArtists);
    allArtistsTrack = allArtistsTrack.map(artist => { return { valid: spotifyUriIsValid(artist.spotify_uri), name: artist.name } });
    let invalidArtistUri = allArtistsTrack.find(artistValid => artistValid.valid === false);

    if (noRequireCollRoles(trackDataToUpdate.collaborators, trackDataToUpdate.isInstrumental)) {
      setCollRolesInvalid(true); return;
    }
    // if (invalidCollaboratorsNames(trackDataToUpdate.collaborators)) {
    //   setCollNamesInvalid(true); return;
    // }
    if (invalidArtistUri) {
      scrollToTop();
      return;
    }
    if (validator.current.allValid()) handleEditTrack();
    else {
      validator.current.showMessages();
      forceUpdate();
    }
  }

  const deleteArtistFromArtists = index => trackDataToUpdate.artists.filter((_, i) => i !== index);
  const handleDeleteOtherArtist = indexOtherArtist => setTrackDataToUpdate({ ...trackDataToUpdate, artists: deleteArtistFromArtists(indexOtherArtist) });

  const handleChangePrimaryOtherArtist = (index, newPrimaryValue) => {
    const newArtists = cloneDeepLimited(trackDataToUpdate.artists);
    newArtists[index].primary = newPrimaryValue;
    setTrackDataToUpdate({ ...trackDataToUpdate, artists: newArtists });
  };

  return (
    <Dialog open={openEditTrack} onClose={handleCancelDialog} aria-labelledby="form-dialog-edit-title" maxWidth="xl" fullWidth>

      <InfoActionDialog id='coll-names-invalid' isOpen={collNamesInvalid} handleClose={() => setCollNamesInvalid(false)}
        title={'Nombre de colaboradores invalidos'} contentTexts={invalidCollNames} />

      <InfoActionDialog id='coll-roles-invalid' isOpen={collRolesInvalid} handleClose={() => setCollRolesInvalid(false)}
        title={'Roles de colaboradores invalidos'} contentTexts={invalidCollRoles} handleOk={() => setCollRolesInvalid(false)} />

      <EditOrAddFieldsDialog labelTextField={openEditDialog.title}
        loading={openLoader} buttonState={buttonState} editOptions={openEditDialog}
        setEditOptions={setOpenEditDialog} />

      <DialogTitle id="form-dialog-edit-title" textAlign='center' sx={{ fontSize: "2em" }}>
        Editar metadata de la Canción
      </DialogTitle>

      <DialogContent>

        <DialogTitle id="agregarArtist-title" sx={agregarArtistaTitleStyle}>Artistas de la Canción</DialogTitle>

        <DialogContentText textAlign='center'>
          {editTrackArtistsInfo}
        </DialogContentText>

        <Grid ref={topElementRef} container spacing={2} style={{ textAlign: "center" }} >

          <Grid container item spacing={2} sx={{ marginTop: "10px" }}>
            {trackDataToUpdate?.artists.length > 0
              ? trackDataToUpdate.artists.map((_, index) =>
                <Grid item xs={3} justifyContent="center" sx={{ height: '70px' }}>
                  <ArtistInAddTrack
                    key={index}
                    index={index}
                    handleDelete={handleDeleteOtherArtist}
                    handleSliderChange={handleChangePrimaryOtherArtist}
                    artists={trackDataToUpdate.artists}
                    allOtherArtists={trackDataToUpdate.allOtherArtists}
                    disabledSlider={albumWithOneTrack}
                  />
                </Grid>)
              : []
            }
          </Grid>

          {!albumWithOneTrack && <AddOtherArtistsTrackForm
            checkBoxLabel="¿Quieres agregar otro artista?"
            checkBoxHelper="Agrega artistas Principales o Featuring que no aparecen en el Lanzamiento."
            checkBoxColor="#508062"
            buttonColor="#508062"
            setTrackData={setTrackDataToUpdate}
            trackData={trackDataToUpdate}
            validator={validator}
          />}

          <Grid item xs={12} >
            <DialogTitle id="info-general-dialog-title" sx={collaboratorsTitleStyle}>Información General</DialogTitle>
            {albumWithOneTrack && <>
              <DialogContentText>Recuerda que no puedes cambiar el nombre ni la versión del track al ser un single.</DialogContentText>
              <DialogContentText>Si quieres cambiar estos valores, hazlo desde el lanzamiento (próximamente).</DialogContentText>
            </>}
          </Grid>

          <>
            <Grid item xs={6}>
              <TextFieldWithInfo
                name="title"
                fullWidth
                disabled={albumWithOneTrack}
                required
                label="Nombre de la Canción"
                value={trackDataToUpdate.title}
                onChange={(event) => setTrackDataToUpdate({ ...trackDataToUpdate, title: event.target.value })}
                helperText="Nombre exacto de la canción, respetando mayúsculas, minúsculas y acentos."
                validatorProps={{ restrictions: 'required|max:100', message: "Debes ingresar el nombre de la Canción.", validator }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextFieldWithInfo
                name="version"
                fullWidth
                disabled={albumWithOneTrack}
                label="Versión de la Canción (opcional)"
                value={trackDataToUpdate.version}
                onChange={(event) => setTrackDataToUpdate({ ...trackDataToUpdate, version: event.target.value })}
                helperText={assetVersionsHelper}
                validatorProps={{ restrictions: 'max:100', message: "Máximo de 100 carácteres.", validator }}
              />
            </Grid>
          </>

          <Grid item xs={12}>
            {collaboratorsNewTrackText}
          </Grid>

          <AddCollaboratorsForm setTrackData={setTrackDataToUpdate} trackData={trackDataToUpdate}
            validator={validator} disabled={false} isEditing={true} />

        </Grid>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancelDialog} sx={buttonColorStyle}>
          Atras
        </Button>
        <Button onClick={allFieldsValidCreateTrack} sx={buttonColorStyle}>
          Editar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const collaboratorsTitleStyle = { fontSize: "1.5em", textAlign: "center", paddingTop: "16px", paddingBottom: 0 };
const agregarArtistaTitleStyle = { fontSize: "1.5em", textAlign: "center" };
const buttonColorStyle = { color: "#508062" };