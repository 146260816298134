import * as ReducerTypes from 'redux/actions/Types';
import * as FirestoreServices from 'services/FirestoreServices.js';
import * as BackendCommunication from 'services/BackendCommunication.js';
import { createAlbumModel, createEditAlbumModel, createEditTrackModel } from 'services/CreateModels';
import { writeCloudLog } from '../../services/LoggingService';
import { createCoverModel } from '../../services/CreateModels';
import { getNewAlbumStateWhenAppleActionNeeded, getDspsToRedeliverDeliverAndTakenDown } from 'utils/delivery.utils';
import { DELIVERED, DELIVERED_NEED_APPLE_REVISION, LOADING, TAKEN_DOWN } from 'variables/varias';
import { toWithOutError } from 'utils';
import { basicSuccess } from './NotificationsHandlerActions';

export const albumsAddStore = albums => {
  return {
    type: ReducerTypes.ADD_ALBUMS,
    payload: albums
  }
}

export const albumsReorderStore = orderByField => {
  return {
    type: ReducerTypes.ALBUMS_REORDER,
    payload: orderByField
  }
}

export const getFugaAlbumByFugaIdRedux = fugaId => async dispatch => {
  let albumByFugaId = await BackendCommunication.getFugaAlbumById(fugaId, dispatch);
  if (albumByFugaId === "EMPTY") return "EMPTY";
  if (albumByFugaId.id === Number(fugaId)) return albumByFugaId;
  else return "ERROR";
}

//Los errores los manejan las funciones a las que llamo.
export const createAlbumRedux = (album, userId, ownerEmail, explicit, myTracks, artistsInvitedStore) => async dispatch => {

  let upcCurrentSystem = album.upc ? "upc-from-user" : await FirestoreServices.getSystemPropOfUpc(dispatch);
  if (!album.upc && upcCurrentSystem === "la-flota") {
    let responseUPC = await FirestoreServices.getUpcFromFS(dispatch);
    if (responseUPC !== "ERROR") album.upc = responseUPC;
  }

  let dspsToDelivery = album.dsps.filter(dsp => dsp.checked);
  let deliveryToApple = Boolean(dspsToDelivery.find(dspInfo => dspInfo.dspName === "Apple Music"));
  album.ownerId = userId; album.ownerEmail = ownerEmail;

  let formDataAlbum = createAlbumModel(album, explicit, myTracks, artistsInvitedStore, deliveryToApple);

  writeCloudLog(`creating album ${album.title} y email: ${ownerEmail}, model to send fuga `, album, { notError: "not error" }, "info");

  let albumFromThirdWebApi = await BackendCommunication.createAlbumFuga(formDataAlbum, ownerEmail, dispatch)
  if (albumFromThirdWebApi === "ERROR") return "ERROR";

  album.fugaId = albumFromThirdWebApi.data.response.albumId; album.state = LOADING;
  album.whenCreatedTS = new Date().getTime();
  album.lastUpdateTS = album.whenCreatedTS;

  let albumToUploadToFS = { ...album, cover: { name: album.cover.name, size: album.cover.size } };
  albumToUploadToFS.dsps = dspsToDelivery.map(dspInfo => { return { dspId: dspInfo.dspId, dspName: dspInfo.dspName, label: dspInfo.dspName, fugaId: dspInfo.fugaId } });
  writeCloudLog(`creating album ${albumToUploadToFS.title} y email: ${ownerEmail}, post fuga pre fs`, albumToUploadToFS, { notError: "not error" }, "info");

  await FirestoreServices.createElementFS(albumToUploadToFS, albumToUploadToFS.id, userId, "albums", "totalAlbums", 1, dispatch);
  await toWithOutError(dispatch(createUPCToSuccessAlbumRedux(albumToUploadToFS)));

  dispatch({
    type: ReducerTypes.ADD_ALBUMS,
    payload: [albumToUploadToFS]
  });

  return albumToUploadToFS;
}

export const albumsUploadCoverRedux = (albumFugaId, coverFile, coverFugaId, ownerEmail) => async dispatch => {
  let fugaDataCover = createCoverModel(coverFile, coverFugaId);
  writeCloudLog(`editing cover for album ${albumFugaId}, y email: ${ownerEmail}, model to send fuga `,
    { filename: coverFile.filename, size: coverFile.size / 1000000, coverFugaId }, { notError: "not error" }, "info");

  let coverUploadResponse = await BackendCommunication.uploadCoverFuga(albumFugaId, fugaDataCover, ownerEmail, dispatch)
  if (coverUploadResponse === "ERROR") return "ERROR";
  return "SUCCESS";
}

export const albumsEditRedux = (allOldAlbumData, newAlbumData, ownerEmail, editInFuga, albumFugaData) => async dispatch => {
  let fugaDataAlbum = editInFuga ? createEditAlbumModel(newAlbumData, albumFugaData) : "NO FUGA EDIT";

  writeCloudLog(`editing album ${newAlbumData.title || allOldAlbumData.title} y email: ${ownerEmail}, model to send fuga `, newAlbumData, { notError: "not error" }, "info");

  if (editInFuga) {
    let albumFromThirdWebApi = await BackendCommunication.editAlbumFuga(fugaDataAlbum, allOldAlbumData.fugaId, ownerEmail, dispatch);
    if (albumFromThirdWebApi === "ERROR") return "ERROR";

    // Edito el nombre de la unica cancion en caso de ser un SINGLE.
    if ((newAlbumData.title || newAlbumData.version) && albumFugaData.assets.length === 1) {
      let editTrackFormData = createEditTrackModel({ name: newAlbumData.title, asset_version: newAlbumData.version });
      await toWithOutError(BackendCommunication.editTrackFugaById(editTrackFormData, albumFugaData.assets[0].id, dispatch));
    }
  }

  await FirestoreServices.updateElementFS(allOldAlbumData, newAlbumData, allOldAlbumData.id, "albums", dispatch);

  dispatch({
    type: ReducerTypes.ALBUMS_EDIT_BY_ID,
    payload: { ...allOldAlbumData, ...newAlbumData }
  });
}

export const albumsDeleteOrTakenDownRedux = (albumData, albumWithPossibleRoyalties, changedState, showNotification) => async dispatch => {

  writeCloudLog(`try to delete or taken down album ${albumData.title} y email: ${albumData.ownerEmail}`, albumData, { notError: "not error" }, "info");

  // El LANZAMIENTO 24/7 de Mercuriales que se da de baja "solo
  if (albumData.fugaId === 6464155126) return;
  if (albumData.fugaId) {
    let deleteResponse = await BackendCommunication.deleteAlbumFuga(albumData.fugaId, dispatch);
    if (deleteResponse === "ERROR") return "ERROR";
  }

  // delete o cambiar de estado mejor dicho.
  await FirestoreServices.deleteAlbumFS(albumData, albumWithPossibleRoyalties, changedState, dispatch);

  await FirestoreServices.deleteAllTracksFromAlbumIdFS(albumData.id, albumData.ownerId, dispatch);

  // Ya se hizo el cambio en store previamente si viene infraccion.
  if (changedState) return "SUCCESS";
  albumWithPossibleRoyalties
    ? dispatch({ type: ReducerTypes.ALBUMS_EDIT_BY_ID, payload: { ...albumData, state: TAKEN_DOWN } })
    : dispatch({ type: ReducerTypes.ALBUMS_DELETE_BY_ID, payload: albumData.id });

  showNotification && dispatch(basicSuccess(`Lanzamiento dado de baja: ${albumData.title}`, false));
  return "SUCCESS";
}

export const albumGetLiveLinkRedux = albumData => async dispatch => {
  let liveLinksResponse = await BackendCommunication.getAlbumLiveLinksById(albumData.fugaId, dispatch);
  if (liveLinksResponse === "ERROR") return "ERROR";
  if (liveLinksResponse.length > 0) {
    albumData.liveLink = liveLinksResponse;
    dispatch({
      type: ReducerTypes.ADD_ALBUMS,
      payload: [albumData]
    });
  }
  return liveLinksResponse;
}

// TODO urgent
// const checkUPCIsNotUsed = () => {

// }

export const createUPCToSuccessAlbumRedux = dataAlbumFuga => async dispatch => {
  if (dataAlbumFuga.upc) return "ALREADY_HAS_UPC";
  let responseUPC = await BackendCommunication.createUPCToSuccessAlbumFuga(dataAlbumFuga.fugaId, dataAlbumFuga.ownerEmail, dispatch);
  if (responseUPC === "ERROR") return "ERROR";

  dataAlbumFuga.upc = responseUPC;

  await FirestoreServices.updateElementFS(dataAlbumFuga, { upc: responseUPC }, dataAlbumFuga.id, "albums", dispatch);

  writeCloudLog(`created UPC to album ${dataAlbumFuga.title} y email: ${dataAlbumFuga.ownerEmail}`, { upc: dataAlbumFuga.upc }, { notError: "not error" }, "info");

  dispatch({
    type: ReducerTypes.ALBUMS_EDIT_BY_ID,
    payload: dataAlbumFuga
  });
}

export const albumAddDspsStore = (dataAlbumFuga, dsps) => async dispatch => {
  dataAlbumFuga.dsps = dsps;
  dispatch({
    type: ReducerTypes.ALBUMS_EDIT_BY_ID,
    payload: dataAlbumFuga
  });
}

export const getAlbumsByFieldRedux = (field, fieldValue, limit) => async dispatch => {
  let albumsByField = await FirestoreServices.getElementsByField('albums', field, fieldValue, dispatch, limit);
  if (albumsByField === "EMPTY") return "EMPTY";
  if (Array.isArray(albumsByField) && albumsByField.length > 0) dispatch(albumsAddStore(albumsByField));
  else return "ERROR";
  return albumsByField;
}

export const getAlbumsByFieldsRedux = (fields, fieldsValue, limit) => async dispatch => {
  let albumsByField = await FirestoreServices.getElementsByFields('albums', fields, fieldsValue, dispatch, limit);
  if (albumsByField === "EMPTY") return "EMPTY";
  if (Array.isArray(albumsByField) && albumsByField.length > 0) dispatch(albumsAddStore(albumsByField));
  else return "ERROR";
  return albumsByField;
}

// Asumo que el album cumple los requisitos para realizar el delivery.
export const albumsPublishAndDeliveryRedux = (albumData, dspsToDelivery, targetDelivery, deliverToApple) => async dispatch => {
  albumData.dsps = dspsToDelivery;
  let responsePublish = await BackendCommunication.publishAlbumFuga(albumData, dispatch);
  if (responsePublish === "ERROR") return "ERROR";
  albumData.state = "PUBLISHED";


  writeCloudLog(`Album ${albumData.title} PUBLISHED with email: ${albumData.ownerEmail}`, { state: albumData.state }, { notError: "not error" }, "info");

  let responseDelivery = await BackendCommunication.deliverAlbumFuga(albumData, targetDelivery === 'delivering-apple', dispatch);
  if (responseDelivery === "ERROR") return "PUBLISHED";
  albumData.state = (deliverToApple && targetDelivery !== 'delivering-apple') ? DELIVERED_NEED_APPLE_REVISION : DELIVERED;

  await FirestoreServices.updateElementFS(albumData, { state: albumData.state }, albumData.id, "albums", dispatch);

  writeCloudLog(`Album ${albumData.title} ${albumData.state} with email: ${albumData.ownerEmail}`, { state: albumData.state }, { notError: "not error" }, "info");

  dispatch({
    type: ReducerTypes.ALBUMS_EDIT_BY_ID,
    payload: albumData
  });

  dispatch(basicSuccess(`Delivery exitoso: ${albumData.title}`, false));
  return "DELIVERED";
}

export const albumsDeliverAllWhenTakenDownRedux = (albumData, updateState) => async dispatch => {
  let responsePublish = await BackendCommunication.publishAlbumFuga(albumData, dispatch);
  if (responsePublish === "ERROR") return "ERROR";
  albumData.state = "PUBLISHED";

  let responseDeliverAll = await dispatch(albumsDeliverToSelectedDspsRedux(albumData, albumData.dsps.map(dspInfo => { return { dsp: dspInfo.dspId } })));
  if (responseDeliverAll === "ERROR") return "ERROR";
  if (updateState) await toWithOutError(dispatch(albumsEditRedux(albumData, { state: DELIVERED }, albumData.ownerEmail, false)));
  dispatch(basicSuccess(`Redelivery exitoso: ${albumData.title}`, false));
  return "REDELIVERED";
}

export const albumsRedeliverAllRedux = (albumData, updateState) => async dispatch => {
  let responsePublish = await BackendCommunication.redeliverAllAlbumFuga(albumData, dispatch);
  if (responsePublish === "ERROR") return "ERROR";
  if (updateState)
    await toWithOutError(dispatch(albumsEditRedux(albumData, { state: DELIVERED }, albumData.ownerEmail, false)));
  return "REDELIVERED";
}

export const albumsRedeliverToSelectedDspsRedux = (albumData, dspsToRedeliverIds) => async dispatch => {
  if (dspsToRedeliverIds.length === 0) return "SUCCESS";
  let responseRedelivery = await BackendCommunication.redeliverAlbumToSelectedDspsFuga(albumData, dspsToRedeliverIds, dispatch);
  if (responseRedelivery === "ERROR") return "ERROR_REDELIVERY";
  return "SUCCESS";
}

export const albumsDeliverToSelectedDspsRedux = (albumData, dspsToDedeliverIds) => async dispatch => {
  if (dspsToDedeliverIds.length === 0) return "SUCCESS";
  let responseDelivery = await BackendCommunication.deliverAlbumToSelectedDspsFuga(albumData, dspsToDedeliverIds, dispatch);
  if (responseDelivery === "ERROR") return "ERROR_DELIVERY";
  return "SUCCESS";
}

export const albumsTakenDownToSelectedDspsRedux = (albumData, dspsToTakeDownIds) => async dispatch => {
  if (dspsToTakeDownIds.length === 0) return "SUCCESS";
  let responseDelivery = await BackendCommunication.takenDownAlbumToSelectedDspsFuga(albumData, dspsToTakeDownIds, dispatch);
  if (responseDelivery === "ERROR") return "ERROR_TAKEN_DOWN";
  return "SUCCESS";
}

export const albumReleasedMakeDeliveryActionsRedux = (albumData, dsps, albumWasEdited) => async dispatch => {
  let responsePublish = await BackendCommunication.publishAlbumFuga(albumData, dispatch);
  if (responsePublish === "ERROR") return "ERROR";

  let { redeliverDsps, deliverDsps, takenDownDsps, appleAction } = getDspsToRedeliverDeliverAndTakenDown(dsps);
  let allResults = [];

  if (albumWasEdited) allResults.push(await dispatch(albumsRedeliverToSelectedDspsRedux(albumData, redeliverDsps.map(dspInfo => { return { dsp: dspInfo.dspId } }))));
  if (!albumWasEdited) allResults.push(await dispatch(albumsTakenDownToSelectedDspsRedux(albumData, takenDownDsps.map(dspInfo => { return { dsp: dspInfo.dspId } }))));
  allResults.push(await dispatch(albumsDeliverToSelectedDspsRedux(albumData, deliverDsps.map(dspInfo => { return { dsp: dspInfo.dspId } }))));

  let newStateFromAppleAction = getNewAlbumStateWhenAppleActionNeeded(appleAction, albumData.state, albumWasEdited);
  if (newStateFromAppleAction !== albumData.state) {
    await FirestoreServices.updateElementFS(albumData, { state: newStateFromAppleAction }, albumData.id, "albums", dispatch);
    dispatch({ type: ReducerTypes.ALBUMS_EDIT_BY_ID, payload: { ...albumData, state: newStateFromAppleAction } });
  }
  else {
    if (albumData.state !== DELIVERED) {
      await FirestoreServices.updateElementFS(albumData, { state: DELIVERED }, albumData.id, "albums", dispatch);
      dispatch({ type: ReducerTypes.ALBUMS_EDIT_BY_ID, payload: { ...albumData, state: DELIVERED } });
    }
  }
  return allResults;
}

export const albumsTakingDownByInfractionRedux = (albumData, actions, infractionObs) => async dispatch => {
  let infractionState = albumData.state;
  let { sendEmail, hideRoyalties, takedown } = actions;
  let { ownerEmail, title, upc, nombreArtist } = albumData;
  let allResults = [];

  if (sendEmail) allResults.push(await BackendCommunication.sendInfractionCopyright(infractionState, ownerEmail, title, upc, nombreArtist, infractionObs, dispatch));
  if (hideRoyalties) allResults.push(await BackendCommunication.hideOrShowRoyaltiesToUser(albumData.upc, [], "not-show", dispatch));
  if (takedown) allResults.push(await dispatch(albumsDeleteOrTakenDownRedux(albumData, true, infractionState, true)));
  return allResults;
}

export const albumCleanUpdatingAlbum = () => {
  return {
    type: ReducerTypes.ALBUMS_CLEAN_ADDING_ALBUM,
  }
}

export const updateAddingAlbumRedux = newAddingAlbum => {
  return {
    type: ReducerTypes.ALBUMS_UPDATE_ADDING_ALBUM,
    payload: newAddingAlbum
  }
}

export const updateAddingAlbumImageUrlAndCoverRedux = ({ imagenUrl, cover }) => {
  return {
    type: ReducerTypes.ALBUMS_UPDATE_ADDING_ALBUM_IMAGEN_URL_AND_FILE,
    payload: { imagenUrl, cover }
  }
}

export const updateNameOtherArtistsAlbumRedux = (nameValue, otherArtistIndex) => {
  return {
    type: ReducerTypes.ALBUMS_UPDATE_OTHER_ARTIST_NAME,
    payload: { nameValue, otherArtistIndex }
  }
}

export const updatePrimaryOtherArtistsAlbumRedux = (isPrimary, otherArtistIndex) => {
  return {
    type: ReducerTypes.ALBUMS_UPDATE_OTHER_ARTIST_PRIMARY,
    payload: { isPrimary, otherArtistIndex }
  }
}

export const updateIdentifierOtherArtistsAlbumRedux = (identifierValue, identifierField, otherArtistIndex) => {
  return {
    type: ReducerTypes.ALBUMS_UPDATE_OTHER_ARTIST_IDENTIFIER,
    payload: { identifierValue, identifierField, otherArtistIndex }
  }
}
