import { v4 as uuidv4 } from 'uuid';
import { peopleRoles } from 'variables/collaborators.variables';

export const deleteWeirdCharacters = text => {
  return text.normalize('NFD')
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
    .normalize();
}

export const getAllPeopleToCreateFromUploadingTracks = uploadedTracks => {
  let people = [];
  uploadedTracks.forEach(track => {
    track.collaborators.forEach(coll => {
      if (!coll.personExistsInFuga && !people.map(p => p.name).includes(coll.name)) people.push({ name: coll.name });
    })
  });
  return people;
}

export const getPeopleToCreateFromEditTrack = colls => {
  let peopleToCreate = [];
  colls.forEach(coll => {
    if (!peopleToCreate.map(p => p.name).includes(coll?.person?.name || "") && coll.person.id === "create-new")
      peopleToCreate.push({ name: coll.person.name });
  })
  return peopleToCreate;
}

const getPersonIdFromPeople = (peopleWithId, personName) => {
  let peopleWiIdNotNull = peopleWithId.filter(person => person !== null && person.id !== null);
  let result = peopleWiIdNotNull.find(person =>
    deleteWeirdCharacters(person.name.toLowerCase()) === deleteWeirdCharacters(personName.toLowerCase()));
  if (result && result.id !== "") return result.id;
}

export const getAllCollaboratorsToAttachFromUploadingTracks = (uploadedTracks, peopleWithId, ownerId, ownerEmail) => {
  let collaboratorsForEachTrack = [];
  uploadedTracks.forEach(track => {
    track.collaborators.forEach(coll => {
      if (coll.name !== "") {
        coll.roles.forEach(collRol => {
          let collToAdd = {
            trackFugaId: track.fugaId, id: uuidv4(), added: false, ownerEmail, ownerId,
            name: coll.name, role: getRoleIdByName(collRol), person: getPersonIdFromPeople(peopleWithId, coll.name)
          }
          // Chequeo que no se agregaron mismos colaboradores con mismo rol a un Track.
          if (!collaboratorsForEachTrack.find(collAdded => (collAdded.trackFugaId === collToAdd.trackFugaId)
            && (collAdded.name === collToAdd.name) && (collAdded.role === collToAdd.role)))
            collaboratorsForEachTrack.push(collToAdd);
        })
      }
    })
  });
  return collaboratorsForEachTrack;
}

export const getCollaboratorsToAddFromEditTrack = (collaborators, peopleWithId, trackFugaId, ownerId, ownerEmail) => {
  let collsToUpdate = [];
  collaborators.forEach(coll => {
    if (coll.id === 'create-new') {
      let newColl = {
        trackFugaId, id: uuidv4(), name: coll.person.name, ownerId, ownerEmail, role: coll.role.id,
        person: coll.person.id === 'create-new' ? getPersonIdFromPeople(peopleWithId, coll.person.name) : coll.person.id
      }
      const isDuplicate = collsToUpdate.some(existingColl =>
        existingColl.name === newColl.name && existingColl.role === newColl.role
      );
      if (!isDuplicate) collsToUpdate.push(newColl);
    }
  })
  return collsToUpdate;
}

export const getAllCollaboratorsBasicInfoFromTracks = uploadedTracks => {
  let collaboratorsForEachTrack = [];
  uploadedTracks.forEach((track, trackIndex) => {
    track.collaborators.forEach(coll => {
      if (coll.name !== "") {
        coll.roles.forEach(collRol => {
          let collToShow = { name: coll.name, role: getRoleIdByName(collRol), key: track.id + coll.name + getRoleIdByName(collRol), trackNumber: trackIndex + 1 };
          if (!collaboratorsForEachTrack.find(collAdded => collToShow.key === collAdded.key))
            collaboratorsForEachTrack.push(collToShow);
        })
      }
    })
  });
  return collaboratorsForEachTrack;
}

export const fromCollaboratorsToContributors = (myCollaborators, fugaCollaborators) => {
  console.log("COLLS: ", myCollaborators);
  console.log("FUGA COLLS: ", fugaCollaborators);
  let trimmedCollabs = myCollaborators.map(collab => { return { ...collab, name: collab.name.trim() } });
  let noEmptyCollabs = trimmedCollabs.filter(collab => collab.name !== "");
  return noEmptyCollabs.flatMap(myCollab => {
    return myCollab.roles.map(myRole => {
      // Buscar si ya existe el colaborador en FUGA por nombre
      const existingFugaPerson = fugaCollaborators.find(fugaCollab => fugaCollab.person.name.trim() === myCollab.name);

      if (existingFugaPerson) {
        // Buscar si el rol también ya existe para esa persona en FUGA
        const existingRole = fugaCollaborators.find(
          fugaCollab => fugaCollab.person.name.trim() === myCollab.name && fugaCollab.role.id === getRoleIdByName(myRole)
        );

        if (existingRole) {
          // Si ya existe el colaborador con el mismo rol en FUGA, reutilizamos todo
          return {
            id: existingRole.id,
            person: {
              id: existingRole.person.id,
              name: existingRole.person.name
            },
            role: { id: existingRole.role.id }
          };
        } else {
          // Si la persona existe pero el rol no, se crea un nuevo colaborador con el mismo `person.id` pero con un rol nuevo
          return {
            id: 'create-new', // Generar un nuevo ID para el colaborador
            person: {
              id: existingFugaPerson.person.id,
              name: existingFugaPerson.person.name
            },
            role: { id: getRoleIdByName(myRole) }
          };
        }
      } else {
        // Si el colaborador no existe, se crea uno nuevo
        return {
          id: "create-new", // Generar nuevo ID para el colaborador
          person: {
            id: "create-new", // Generar nuevo ID para la persona
            name: myCollab.name
          },
          role: { id: getRoleIdByName(myRole) }
        };
      }
    });
  });
}

export const detectRemovedCollaborators = (myCollaborators, fugaCollaborators) => {
  const removedCollaborators = fugaCollaborators.filter(fugaCollab => {
    // Verificamos si el colaborador con el rol en FUGA ya no está en la nueva lista
    const stillExists = myCollaborators.some(myCollab => {
      return myCollab.name === fugaCollab.person.name &&
        myCollab.roles.includes(getRoleNameById(fugaCollab.role.id));
    });
    return !stillExists; // Si no existe, significa que fue eliminado
  });

  return removedCollaborators;
};

export const mapFugaCollaboratorsToOurs = fugaColls => {
  if (!fugaColls) return [];
  let collaborators = [];
  fugaColls.forEach(fugaColl => {
    let result = { name: fugaColl.person.name, roles: [getRoleNameById(fugaColl.role.id)] };
    collaborators.push(result);
  })
  return collaborators;
}

export const getRolesNamesById = roles => {
  return roles.map(rolId => peopleRoles.find(rolObject => rolObject.id === rolId)?.name || "")
}

export const getRoleNameById = roleId => {
  return peopleRoles.find(rolObject => rolObject.id === roleId)?.name || "";
}

export const getRoleIdByName = roleName => {
  return peopleRoles.find(rolObject => rolObject.name === roleName)?.id || "";
}

export const invalidCollaboratorsNames = colls => {
  let names = colls.map(coll => coll.name);
  if (names.some(name => name.split(' ').length < 2)) return true;
  return false;
}

export const noRequireCollRoles = (colls, isInstrumental) => {
  let allRoles = colls.flatMap(coll => coll.roles);
  if (isInstrumental && allRoles.includes("Compositor")) return false;
  if (!isInstrumental && allRoles.includes("Compositor") && allRoles.includes("Liricista")) return false;
  return true;
}