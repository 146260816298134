import { Grid } from '@mui/material';
import { trackUploadProgress } from 'utils/tables.utils';
import { generateTestTrackData } from 'factory/tracks.factory';
import { EditTrackDialog } from 'views/Tracks/EditTrackDialog';

const DashboardDefault = () => {

  let trackTest = generateTestTrackData(1, "Test Version", "");

  return (
    <Grid item xs={12}>
      <EditTrackDialog openDialog={true} setOpenNewTrackDialog={() => console.log("CLOSE")} setTracksDataTable={() => []}
        tracksDataTable={[]} trackData={trackTest} setTrackData={() => {}} circularProgress={(progress) => trackUploadProgress(progress)} />
    </Grid>
  );
};

export default DashboardDefault;
