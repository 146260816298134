import { mapFugaCollaboratorsToOurs } from 'utils/collaborators';
import { v4 as uuidv4 } from 'uuid';
import { allAudioLocalesFuga, languagesFuga } from 'variables/varias';

export const generateTestTrackData = (position, title, isrc) => {
  return {
    allOtherArtists: [],
    artistFugaId: "5097651765",
    artists: [{ name: "Julieta Venegas TEST", fugaId: "5097651765", primary: true, id: "361b5219-5ec1-476d-a19d-75d019c6a8d1" },
    { name: "Julieta Venegas TEST", fugaId: "5097651765", primary: true, id: "361b5219-5ec1-476d-a19d-75d019c6a8d1" },
    { name: "Julieta Venegas TEST", fugaId: "5097651765", primary: true, id: "361b5219-5ec1-476d-a19d-75d019c6a8d1" },
    { name: "Julieta Venegas", fugaId: "5097652765", primary: false, id: "361b5219-5ec1-476d-a19d-75d019c6a8d1" }],
    audio_locale_id: "ZXX",
    audio_locale_name: "Instrumental",
    collaborators: [{ name: 'Juanito', roles: ["COMPOSER", "ACTOR"] }, { name: 'Juanito', roles: ["COMPOSER"] }, { name: 'El Perro', roles: ["COMPOSER"] }],
    disc_number: "",
    existedInFuga: false,
    explicit: false,
    fugaId: "",
    genre: "FOLK",
    genreName: "Folk",
    id: uuidv4(),
    isrc,
    lyrics: "",
    ownerId: "f1XEmZVlBdRHletHlqoLOqkAqVT2",
    position,
    preOrder: false,
    preview: false,
    price: "",
    primary_artist: "Julieta Venegas TEST",
    progress: 0,
    subgenre: "",
    subgenreName: "",
    version: "Live",
    title,
    track: { name: "24_bit_fixes.wav" },
    track_language_id: "ES",
    track_language_name: "Spanish"
  }
}

export const generateTestTracksData = titles => {
  return titles.map((title, index) => generateTestTrackData(index, title, ""))
}

export const createOurTrackFromFugaTrack = (trackFuga, trimmedISRC) => {
  return {
    explicit: false, allOtherArtists: [],
    subgenre: trackFuga.subgenre?.id || "", genreName: trackFuga.genre?.name || "",
    subgenreName: trackFuga.subgenre?.name || "", genre: trackFuga.genre?.id || "",
    track: { size: trackFuga.audio.file_size, name: trackFuga.audio.original_filename },
    artists: trackFuga.artists, lyrics: trackFuga.lyrics, isrc: trimmedISRC,
    track_language_name: languagesFuga.find(l => l.id === trackFuga.language)?.name || "Spanish",
    track_language_id: trackFuga.language, title: trackFuga.name,
    progress: 100, id: "", preview: false, preOrder: false,
    collaborators: mapFugaCollaboratorsToOurs(trackFuga.contributors),
    audio_locale_name: allAudioLocalesFuga.find(l => l.id === trackFuga.audio_locale)?.name || "",
    asset_version: trackFuga.asset_version, fugaId: trackFuga.id, existedInFuga: true,
    audio_locale_id: trackFuga.audio_locale
  };
}

export const createOurTrackToEditFromFugaTrack = trackFuga => {
  return {
    allOtherArtists: [],
    artists: trackFuga.artists || [],
    title: trackFuga.name || "",
    collaborators: mapFugaCollaboratorsToOurs(trackFuga.contributors),
    asset_version: trackFuga.asset_version,
    fugaId: trackFuga.id,
    isInstrumental: trackFuga.audio_locale === "ZXX"
  };
}