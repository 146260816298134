import { formatAllNumber } from 'utils';
import { DEPOSITO, payoutPayMethodIdToView } from 'variables/financial';
import { getPayoutStatus, iconOpenActionsPayouts, getPaymentId, getTotalsEmptyToShow } from '../utils/payouts.utils';

export const payoutDefaultValues = {
  status: "Esperando confirmación", requestDate: "", transferDate: null, transferMonth: "",
  currency: "ARS", transferTotalAskedCurrency: 0, currencyRateToUsd: 0, transferTotalUsd: 0,
  historicTotalUsd: 0, userCuit: "", id: "", userEmail: "", userId: "", alreadyPaidUsd: "",
  comissionAskedCurrency: 0, comissionCurrency: "", comissionUsd: 0, userName: "", cupon: "",
  userLastName: "", cbuCvuAlias: "", paypalEmail: "", paypalId: "", paypalFee: 0, paymentMethod: DEPOSITO,
  payoneerEmail: "", payoneerFee: 0, payoneerId: "", taxesUsd: 0, taxesOtherCurrency: 0, taxesCurrency: ""
}

export const getPayoutsHeadersForUser = () => [
  { name: "Estado", width: "7%" }, { name: "Día Solicitado", width: "10%" }, { name: "Día Pagado", width: "10%" },
  { name: "Moneda", width: "4%" }, { name: "Transferencia (ARS)", width: "10%" }, { name: "Cotización (USD)", width: "8%" },
  { name: "Transferencia (USD)", width: "10%" }, { name: "Total Solicitado (USD)", width: "12%" },
  { name: "Total Retirado (USD)", width: "13%" }, { name: "Modo de retiro", width: "8%" },
  { name: "ID Pago", width: "6%" }
]

export const getPayoutsHeadersForAdmin = () => [
  { name: "Opciones", width: "3%" }, { name: "Email", width: "14%" }, { name: "Estado", width: "5%" }, { name: "Día Solicitado", width: "8%" },
  { name: "Día Pagado", width: "8%" }, { name: "Moneda", width: "2%" }, { name: "Transferencia (ARS)", width: "7%" },
  { name: "Cotización (USD)", width: "6%" }, { name: "Transferencia (USD)", width: "6%" },
  { name: "Total Solicitado (USD)", width: "13%" }, { name: "Total Retirado (USD)", width: "12%" },
  { name: "Modo de retiro", width: "8%" }, { name: "ID Pago", width: "8%" }
]

export const getPayoutsAccountingHeaders = groupByProp => [
  { name: groupByProp.name, width: "20%" }, { name: "Último pago", width: "20%" },
  { name: "Cantidad de Pagos", width: "20%" }, { name: "Total Pagado (USD)", width: "20%" },
  { name: "Pendiente de Pago (USD)", width: "20%" },
]

export const payoutsGroupByValues = ["Usuario", "Moneda", "Mes del Pago"];

export const createPayoutRowForUser = (payoutRowFromDB, isAdmin) => {
  return {
    status: getPayoutStatus(payoutRowFromDB.status),
    requestDate: payoutRowFromDB.requestDate,
    transferDate: payoutRowFromDB.transferDate || "Esperando pago.",
    currency: payoutRowFromDB.currency,
    transferTotalAskedCurrency: payoutRowFromDB.currency !== "usd"
      ? <b>{formatAllNumber(payoutRowFromDB.transferTotalAskedCurrency, 2, '.')}</b>
      : <b>{formatAllNumber(payoutRowFromDB.transferTotalUsd, 2, '.')}</b>,
    currencyRate: payoutRowFromDB.currencyRateToUsd === 0 ? 1 : formatAllNumber(payoutRowFromDB.currencyRateToUsd, 2, '.'),
    transferTotalUsd: <b>{formatAllNumber(payoutRowFromDB.transferTotalUsd, 2, '.')}</b>,
    historicTotalUsd: formatAllNumber(payoutRowFromDB.historicTotalUsd, 2, '.'),
    alreadyPaidUsd: formatAllNumber(payoutRowFromDB.alreadyPaidUsd, 2, '.'),
    methodPay: payoutPayMethodIdToView[payoutRowFromDB.paymentMethod] || "Sin información",
    id: getPaymentId(payoutRowFromDB, isAdmin),
  }
}

export const createPayoutRowForAdmin = (payoutRowFromDB, setOpenActionsDialog, isRoot) => {
  let payoutForAdmin = {
    options: iconOpenActionsPayouts(payoutRowFromDB.id, setOpenActionsDialog),
    userEmail: payoutRowFromDB.ownerEmail, ...createPayoutRowForUser(payoutRowFromDB, true)
  };
  if (!isRoot) delete payoutForAdmin.options;
  return payoutForAdmin;
}

const createAccPayoutRowForAdmin = (accRow, groupByProp, accPending) => {

  let groupByValue = groupByProp.id === "transferMonth"
    ? !accRow[groupByProp.id] ? accRow.lastPayAskedDay.slice(0, 7) : accRow[groupByProp.id].slice(0, 7)
    : accRow[groupByProp.id]

  let pendingGroupByValue = accPending.find(pendingRow => {
    let pendingValue = groupByProp.id === "transferMonth" ? pendingRow.lastPayAskedDay.slice(0, 7) : pendingRow[groupByProp.id];
    return pendingValue === groupByValue;
  });

  let pendingValue = formatAllNumber(pendingGroupByValue?.totalPayed || 0, 2, '.');
  return {
    [groupByProp.name]: groupByValue,
    lastPayAskedDay: accRow.lastPayAskedDay,
    cantPayouts: formatThousandsPoint(accRow.cantPayouts),
    totalPayed: 'USD ' + formatAllNumber(accRow.totalPayed - (pendingGroupByValue?.totalPayed || 0), 2, '.'),
    pendingPay: <small style={{ fontSize: '1rem', fontWeight: '400', color: parseFloat(pendingValue) > 0 ? "rgb(255, 192, 0)" : "" }}>
      {'USD ' + pendingValue}</small>
  }
}

const formatThousandsPoint = number => number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0;

export const getPayoutAccountingRows = (accPayments, accPendingPayments, groupBy, maxRows, orderByProp) => {
  if (!accPayments || accPayments === "EMPTY" || accPayments.length === 0) return [];
  // let sortedAccRows = sortAccountingRows(wdRows, orderByProp);
  return accPayments.map(accountingRow => createAccPayoutRowForAdmin(accountingRow, groupBy, accPendingPayments)).slice(0, maxRows)
}

export const getTotalsPayoutsAccountingRow = (accountingValues, accPendingValues) => {
  let totals = { email: "Totales", cantPayouts: 0, lastPayAskedDay: 0, totalPayed: 0, pendingPay: 0 };
  if (accountingValues.length === 0) return getTotalsEmptyToShow;

  accountingValues.forEach(accVal => {
    totals.cantPayouts += accVal.cantPayouts;
    totals.totalPayed += accVal.totalPayed;
  })

  accPendingValues.forEach(accPendingVal => totals.pendingPay += accPendingVal.totalPayed);

  return {
    email: <b>{totals.email}</b>,
    lastPayAskedDay: <b>{accountingValues[accountingValues.length - 1].lastPayAskedDay}</b>,
    cantPayouts: <b>{formatThousandsPoint(totals.cantPayouts)}</b>,
    totalPayed: <b>{'USD ' + formatAllNumber(totals.totalPayed - totals.pendingPay, 2, '.')}</b>,
    pendingPay: <b style={{ color: "rgb(255, 192, 0)" }}>{'USD ' + formatAllNumber(totals.pendingPay, 2, '.')}</b>
  }
}
