import axios from 'axios';
import { copyFormDataToJSON, nodeEnv, to, truncateFloat } from '../utils';
import { createBackendError } from '../redux/actions/NotificationsHandlerActions';
import { writeCloudLog } from './LoggingService';
import { logReleaseDeliveryAnalyticEvent, logReleaseRedeliveryAnalyticEvent } from './GoogleAnalytics';
import { getUserDocFS } from './FirestoreServices';
import { getAlbumsByFieldRedux } from 'redux/actions/AlbumsActions';

export const webUrl = "https://dashboard2.laflota.com.ar/filemanagerapp/api/";
export const portUrl = "http://70.39.235.122:5000/filemanagerapp/api/";
export const localUrl = "http://localhost:5000/filemanagerapp/api/";
export const targetUrl = nodeEnv === "production" ? webUrl : localUrl;

// ======================================LABELS=============================================\\

export const createLabelFuga = async (labelName, dispatch) => {
  let [errorCreatingLabelInThirdWebApi, labelFromThirdWebApi] = await to(
    axios.post(`${targetUrl}labels`, { name: labelName }));

  if (errorCreatingLabelInThirdWebApi) {
    dispatch(createBackendError(errorCreatingLabelInThirdWebApi));
    writeCloudLog("Error creating label in fuga", labelName, errorCreatingLabelInThirdWebApi, "error");
    return "ERROR";
  }

  return labelFromThirdWebApi;
}

export const deleteLabelFuga = async (labelFugaId, dispatch) => {
  let [errorDeletingLabelInThirdWebApi] = await to(
    axios.delete(`${targetUrl}labels/${labelFugaId}`));

  if (errorDeletingLabelInThirdWebApi) {
    const errorCodeIfExist = errorDeletingLabelInThirdWebApi.response.data.properties.msgFromFuga.code;
    if (errorCodeIfExist === "NOT_AUTHORIZED" || errorCodeIfExist === "NOT_FOUND") return "NOT_AUTHORIZED";
    writeCloudLog("Error deleting label in fuga", labelFugaId, errorDeletingLabelInThirdWebApi, "error");
    dispatch(createBackendError(errorDeletingLabelInThirdWebApi));
    return "ERROR";
  }

  return "SUCCESS";
}

// ======================================ARTISTS=============================================\\

export const getArtistByIdFuga = async (fugaId, dispatch) => {
  let [errorGettingArtist, artistInFuga] = await to(axios.get(`${targetUrl}artists/${fugaId}`));
  if (errorGettingArtist) {
    dispatch(createBackendError(errorGettingArtist));
    writeCloudLog("Error getting artist in fuga", fugaId, errorGettingArtist, "error");
    return "ERROR";
  }
  return artistInFuga;
}

export const createArtistFuga = async (rawDataArtist, ownerEmail, dispatch) => {
  let [errorUploadingArtistInThirdWebApi, artistFromThirdWebApi] = await to(
    axios.post(`${targetUrl}artists/withIdentifiers`, rawDataArtist));

  if (errorUploadingArtistInThirdWebApi) {
    dispatch(createBackendError(errorUploadingArtistInThirdWebApi));
    writeCloudLog(`Error creating artist in fuga, ownerEmail: ${ownerEmail}`, rawDataArtist, errorUploadingArtistInThirdWebApi, "error");
    return "ERROR";
  }

  return artistFromThirdWebApi;
}

export const updateArtistFuga = async (rawDataArtist, artistFugaId, ownerEmail, dispatch) => {
  let [errorUpdatingArtistInThirdWebApi] = await to(
    axios.put(`${targetUrl}artists/${artistFugaId}`, rawDataArtist));

  if (errorUpdatingArtistInThirdWebApi) {
    dispatch(createBackendError(errorUpdatingArtistInThirdWebApi));
    writeCloudLog(`Error updating artist in fuga, ownerEmail: ${ownerEmail}`, rawDataArtist, errorUpdatingArtistInThirdWebApi, "error");
    return "ERROR";
  }

  return "SUCCESS";
}

export const updateArtistNameInRoyalties = async (oldName, newName, artistId, ownerEmail, dispatch) => {
  let [errorUpdatingArtistInRoyalties] = await to(
    axios.put(`${targetUrl}royalties/artist-name/${artistId}`, { oldName, newName }));

  if (errorUpdatingArtistInRoyalties) {
    dispatch(createBackendError(errorUpdatingArtistInRoyalties));
    writeCloudLog(`Error updating artist name in royalties, ownerEmail: ${ownerEmail}`, { oldName, newName, artistId },
      errorUpdatingArtistInRoyalties, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const getArtistsIdentifierByIdFuga = async (artistFugaId, dispatch) => {
  let [errorGettingArtistIdentfiers, artistsIdentifiers] = await to(axios.get(`${targetUrl}artists/${artistFugaId}/identifier`));
  if (errorGettingArtistIdentfiers) {
    dispatch(createBackendError(errorGettingArtistIdentfiers));
    writeCloudLog("Error getting artist identifiers", errorGettingArtistIdentfiers);
    return "ERROR";
  }
  return artistsIdentifiers.data.response;
}

export const updateArtistIdentifierFuga = async (identifierId, rawDataArtist, artistFugaId, ownerEmail, dispatch) => {
  let errorUpdatingArtistInThirdWebApi = ""; let resultIdentifier = "";
  let errorDeletingIdentifier = "";
  if (rawDataArtist.identifierValue === "") {
    [errorDeletingIdentifier] = await to(axios.delete(`${targetUrl}artists/${artistFugaId}/identifier/${identifierId}`));
    if (errorDeletingIdentifier) {
      dispatch(createBackendError(errorDeletingIdentifier));
      writeCloudLog(`Error deleting artist identifier in fuga, ownerEmail: ${ownerEmail}`, { identifierId, rawDataArtist, artistFugaId }, errorDeletingIdentifier, "error");
      return "ERROR";
    }
    [errorUpdatingArtistInThirdWebApi, resultIdentifier] = await to(
      axios.post(`${targetUrl}artists/${artistFugaId}/identifier`, rawDataArtist));
  }
  else[errorUpdatingArtistInThirdWebApi, resultIdentifier] = await to(
    axios.put(`${targetUrl}artists/${artistFugaId}/identifier`, rawDataArtist));

  if (errorUpdatingArtistInThirdWebApi) {
    dispatch(createBackendError(errorUpdatingArtistInThirdWebApi));
    writeCloudLog("Error updating artist in fuga", { identifierId, artistFugaId, rawDataArtist }, errorUpdatingArtistInThirdWebApi, "error");
    return "ERROR";
  }

  return resultIdentifier.data.response.id;
}


export const deleteArtistFuga = async (artistFugaId, dispatch) => {
  let [errorDeletingArtistInFuga] = await to(axios.delete(`${targetUrl}artists/${artistFugaId}`));

  if (errorDeletingArtistInFuga) {
    // const errorCodeIfExist = errorDeletingArtistInFuga.response.data.properties.msgFromFuga.code;
    // if (errorCodeIfExist === "NOT_AUTHORIZED" || errorCodeIfExist === "NOT_FOUND") return "NOT_AUTHORIZED";
    // dispatch(createBackendError(errorDeletingArtistInFuga));
    writeCloudLog("Error deleting artist in fuga", artistFugaId, errorDeletingArtistInFuga, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const getDgArtistsFuga = async (userEmail, dispatch) => {
  let [errorGettingDgArtists, dgArtists] = await to(axios.get(`${targetUrl}users/searchArtistsByEmail/${userEmail}`));
  if (errorGettingDgArtists) {
    dispatch(createBackendError(errorGettingDgArtists));
    writeCloudLog("Error getting dg artists", userEmail, errorGettingDgArtists, "error");
    return "ERROR";
  }

  if (dgArtists.data.response === "El usuario no tiene Artistas") return "NO_ARTISTS";
  if (dgArtists.data.response === "No existe el Email en La Flota") return "NO_USER";

  return dgArtists.data.response;
}

export const createAlbumFromFugaToArtist = async (albumFugaId, userEmail, userId, artistId, nombreArtist, dispatch) => {
  let [errorCreatingAlbum] = await to(axios.post(`${targetUrl}firebase/fuga/create-fs-album-from-fuga`,
    { albumFugaId, userEmail, userId, artistId, nombreArtist }));
  if (errorCreatingAlbum) {
    dispatch(createBackendError(errorCreatingAlbum));
    writeCloudLog("Error creating album from fuga to Artist", { albumFugaId, artistId, nombreArtist, userEmail }, errorCreatingAlbum, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const artistSendEmailOacAction = async (ownerEmail, artistName, oac, oacAction) => {
  let [errorSendingEmail, resultSendingEmail] = await to(axios.post(`${targetUrl}artists/mailing/${oacAction}-oac`,
    { ownerEmail, artistName, oac }));
  if (errorSendingEmail || resultSendingEmail === "ERROR") {
    dispatch(createBackendError(errorSendingEmail));
    writeCloudLog("Error creating album from fuga to Artist",
      { ownerEmail, artistName, oac, oacAction }, errorSendingEmail, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

// ======================================ALBUMS=============================================\\

export const getFugaAlbumById = async (albumId, dispatch) => {
  let [errorGettingAlbum, albumResponse] = await to(axios.get(`${targetUrl}albums/${albumId}?timestamp=${new Date().getTime()}`));
  if (errorGettingAlbum) {
    dispatch(createBackendError(errorGettingAlbum));
    writeCloudLog("Error getting album from fuga", albumId, errorGettingAlbum, "error");
    return "ERROR";
  }
  return albumResponse.data.response;
}

export const getAlbumFugaImage = async (albumFugaId, addTimestamp, dispatch) => {
  if (!albumFugaId) return "ERROR";
  let [errorGettingFugaImage, albumFugaImageData] = await to(axios.get(`${targetUrl}albums/${albumFugaId}/image/muse_list_view`, {
    params: { timestamp: addTimestamp ? new Date().getTime() : "" },
    responseType: 'blob'
  }));
  if (errorGettingFugaImage) {
    dispatch(createBackendError(errorGettingFugaImage));
    writeCloudLog(`Error getitng album image in fuga: ${albumFugaId}`, errorGettingFugaImage, "error");
    return "ERROR";
  }
  return albumFugaImageData.data;
}

export const createAlbumFuga = async (formDataAlbum, ownerEmail, dispatch) => {
  // ACA VA portUrl
  let [errorUploadingAlbumInThirdWebApi, albumFromThirdWebApi] = await to(axios.post(`${targetUrl}albums`, formDataAlbum));
  if (errorUploadingAlbumInThirdWebApi) {
    dispatch(createBackendError(errorUploadingAlbumInThirdWebApi));
    writeCloudLog(`Error creating album in fuga, ownerEmail: ${ownerEmail}`,
      copyFormDataToJSON(formDataAlbum), errorUploadingAlbumInThirdWebApi, "error");
    return "ERROR";
  }

  return albumFromThirdWebApi;
}

export const editAlbumFuga = async (rawNewDataAlbum, albumFugaId, ownerEmail, dispatch) => {
  let [errorEditingAlbumInThirdWebApi, albumFromThirdWebApi] = await to(axios.put(`${targetUrl}albums/${albumFugaId}`, rawNewDataAlbum));
  if (errorEditingAlbumInThirdWebApi) {
    dispatch(createBackendError(errorEditingAlbumInThirdWebApi));
    writeCloudLog(`Error editing album in fuga, email from who edit: ${ownerEmail}`,
      rawNewDataAlbum, errorEditingAlbumInThirdWebApi, "error");
    return "ERROR";
  }
  return albumFromThirdWebApi;
}

export const uploadCoverFuga = async (albumFugaId, fugaFormDataCover, ownerEmail, dispatch) => {
  // ACA VA portUrl
  let [errorUploadingAlbumInThirdWebApi, albumFromThirdWebApi] = await to(axios.post(`${targetUrl}albums/uploadCover`, fugaFormDataCover));
  if (errorUploadingAlbumInThirdWebApi) {
    dispatch(createBackendError(errorUploadingAlbumInThirdWebApi));
    writeCloudLog(`Error uploading cover album with id: ${albumFugaId} in fuga, ownerEmail: ${ownerEmail}`,
      { data: "UPLOADING COVER" }, errorUploadingAlbumInThirdWebApi, "error");
    return "ERROR";
  }
  return albumFromThirdWebApi;
}

export const getAlbumLiveLinksById = async (albumId, dispatch) => {
  let [errorGettingLiveLinks, liveLinksResponse] = await to(axios.get(`${targetUrl}albums/${albumId}/live_links`));
  if (errorGettingLiveLinks) {
    dispatch(createBackendError(errorGettingLiveLinks));
    writeCloudLog("Error getting live links in fuga", albumId, errorGettingLiveLinks, "error");
    return "ERROR";
  }
  return liveLinksResponse.data.response.live_link;
}

export const deleteAlbumFuga = async (albumFugaId, dispatch) => {
  let [errorDeletingAlbumInFuga] = await to(axios.delete(`${targetUrl}albums/${albumFugaId}?delete_assets=true`));
  if (errorDeletingAlbumInFuga) {
    if (errorDeletingAlbumInFuga.response) {
      const errorCodeIfExist = errorDeletingAlbumInFuga.response.data.data.code;
      let statusText = errorDeletingAlbumInFuga.response.statusText;
      if (errorCodeIfExist === "NOT_AUTHORIZED" || statusText === "Not Found") return "NOT_FOUND";
    }
    dispatch(createBackendError(errorDeletingAlbumInFuga));
    writeCloudLog("Error deleting album in fuga", albumFugaId, errorDeletingAlbumInFuga, "error");
    return "ERROR";
  }

  return "SUCCESS";
}

export const attachTrackToAlbumFuga = async (trackData, dispatch) => {
  const [errorAttachingTrack] = await to(axios.put(`${targetUrl}albums/${trackData.albumFugaId}/tracks/${trackData.fugaId}`));
  if (errorAttachingTrack) {
    dispatch(createBackendError(errorAttachingTrack));
    writeCloudLog(`Error attaching tracks to album in fuga, ownerEmail: ${trackData.ownerEmail}`
      , { trackData, albumId: trackData.albumFugaId }, errorAttachingTrack, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const createUPCToSuccessAlbumFuga = async (albumFugaId, ownerEmail, dispatch) => {
  const [errorCreatingUPC, responseUPC] = await to(axios.post(`${targetUrl}albums/${albumFugaId}/barcode`));
  if (errorCreatingUPC) {
    // Solo hasta que decidamos como manejar los UPCs cuando sale mal. Pero de entrada, no quiero dar error si solo falta UPC.
    // dispatch(createBackendError(errorCreatingUPC));
    writeCloudLog(`Error creating UPC to album in fuga, ownerEmail: ${ownerEmail}`, albumFugaId, errorCreatingUPC, "error");
    return "ERROR";
  }
  return responseUPC.data.response;
}

export const publishAlbumFuga = async (albumData, dispatch) => {
  const [errorPublishingAlbum, responsePublishing] = await to(axios.post(`${targetUrl}albums/${albumData.fugaId}/publish`));
  if (errorPublishingAlbum) {
    dispatch(createBackendError(errorPublishingAlbum));
    writeCloudLog(`Error publishing album with name: ${albumData.title} and UPC: ${albumData.upc}
    , in fuga, ownerEmail: ${albumData.ownerEmail}`, albumData, errorPublishingAlbum, "error");
    return "ERROR";
  }
  return responsePublishing.data.response;
}

export const getDspsDeliveryStatesFuga = async (albumFugaId, ownerEmail, dispatch) => {
  const [errorGettingDspsStates, dspsDeliveryStates] = await to(axios.get(`${targetUrl}albums/${albumFugaId}/delivery_instructions/states?${new Date().getTime()}`));
  if (errorGettingDspsStates) {
    dispatch(createBackendError(errorGettingDspsStates));
    writeCloudLog(`Error getting DSPs states of album with id: ${albumFugaId}, in fuga, ownerEmail: ${ownerEmail}`, albumFugaId, errorGettingDspsStates, "error");
    return "ERROR";
  }
  return dspsDeliveryStates.data.response;
}

export const deliverAlbumFuga = async (albumData, onlyToApple, dispatch) => {
  let dspsIds = [];
  // TODO: Toda esta parte debería ir en un REDUX ACTION (deberia quitar este metodo y usar el de abajo...)
  // Solo hago el delivery a Apple
  if (onlyToApple) dspsIds = [{ dsp: 1330598 }];
  // No hago el delivery automatico a Apple Music.
  else albumData.dsps.forEach(dspInfo => {
    if (dspInfo.dspName !== "Apple Music") dspsIds.push({ dsp: dspInfo.dspId });
    // Si eligió Facebook Music / Instagram, agrego ademas Facebook Fingerprinting (1415672002).
    if (dspInfo.dspName === "Facebook Music / Instagram") dspsIds.push({ dsp: 1415672002 });
  });
  //HASTA ACA!
  const [errorAddingDspsAlbum] = await to(axios.put(`${targetUrl}albums/${albumData.fugaId}/delivery_instructions/edit`, dspsIds));
  if (errorAddingDspsAlbum) {
    dispatch(createBackendError(errorAddingDspsAlbum));
    writeCloudLog(`Error adding DSPs of album with name: ${albumData.title} and UPC: ${albumData.upc}
    , in fuga, ownerEmail: ${albumData.ownerEmail}`, albumData, errorAddingDspsAlbum, "error");
    return "ERROR";
  }

  const [errorMakingDelivery] = await to(axios.post(`${targetUrl}albums/${albumData.fugaId}/delivery_instructions/deliver`, dspsIds));
  if (errorAddingDspsAlbum) {
    dispatch(createBackendError(errorMakingDelivery));
    writeCloudLog(`Error making delivery of album with name: ${albumData.title} and UPC: ${albumData.upc}
    , in fuga, ownerEmail: ${albumData.ownerEmail}`, albumData, errorMakingDelivery, "error");
    return "ERROR";
  }
  //TODO pasar este LOG al redux...
  logReleaseDeliveryAnalyticEvent(albumData);
  return "SUCCESS";
}

export const deliverAlbumToSelectedDspsFuga = async (albumData, dspsIds, dispatch) => {
  // Si eligió Facebook Music / Instagram, agrego ademas Facebook Fingerprinting (1415672002).
  if (dspsIds.find(dspId => dspId.dsp === 1499657856)) dspsIds.push({ dsp: 1415672002 })

  const [errorAddingDspsAlbum] = await to(axios.put(`${targetUrl}albums/${albumData.fugaId}/delivery_instructions/edit`, dspsIds));
  if (errorAddingDspsAlbum) {
    dispatch(createBackendError(errorAddingDspsAlbum));
    writeCloudLog(`Error adding DSPs of album with name: ${albumData.title} and UPC: ${albumData.upc}
    , in fuga, ownerEmail: ${albumData.ownerEmail}`, albumData, errorAddingDspsAlbum, "error");
    return "ERROR";
  }

  const [errorMakingDelivery] = await to(axios.post(`${targetUrl}albums/${albumData.fugaId}/delivery_instructions/deliver`, dspsIds));
  if (errorAddingDspsAlbum) {
    dispatch(createBackendError(errorMakingDelivery));
    writeCloudLog(`Error making delivery of album with name: ${albumData.title} and UPC: ${albumData.upc}
    , in fuga, ownerEmail: ${albumData.ownerEmail}`, { albumData, dspsIds }, errorMakingDelivery, "error");
    return "ERROR";
  }
  return "SUCCESS"
}

export const redeliverAllAlbumFuga = async (albumData, dispatch) => {
  const [errorRedeliveringAlbum] = await to(axios.post(`${targetUrl}albums/${albumData.fugaId}/delivery_instructions/redeliver_all`));
  if (errorRedeliveringAlbum) {
    dispatch(createBackendError(errorRedeliveringAlbum));
    writeCloudLog(`Error REDELIVERING to all DSPs, album with name: ${albumData.title} and UPC: ${albumData.upc}
    , in fuga, ownerEmail: ${albumData.ownerEmail}`, albumData, errorRedeliveringAlbum, "error");
    return "ERROR";
  }
  logReleaseRedeliveryAnalyticEvent(albumData);
  return "SUCCESS";
}

export const redeliverAlbumToSelectedDspsFuga = async (albumData, dspsIds, dispatch) => {
  // Si eligió Facebook Music / Instagram, agrego ademas Facebook Fingerprinting (1415672002).
  if (dspsIds.find(dspId => dspId.dsp === 1499657856)) dspsIds.push({ dsp: 1415672002 })

  const [errorRedeliveringAlbum] = await to(axios.post(`${targetUrl}albums/${albumData.fugaId}/delivery_instructions/redeliver`, dspsIds));
  if (errorRedeliveringAlbum) {
    dispatch(createBackendError(errorRedeliveringAlbum));
    writeCloudLog(`Error REDELIVERING to selected DSPs, album with name: ${albumData.title} and UPC: ${albumData.upc}
    , in fuga, ownerEmail: ${albumData.ownerEmail}`, { albumData, dspsIds }, errorRedeliveringAlbum, "error");
    return "ERROR";
  }
  logReleaseRedeliveryAnalyticEvent(albumData);
}

export const takenDownAlbumToSelectedDspsFuga = async (albumData, dspsIds, dispatch) => {
  const [errorTakenDownAlbum] = await to(axios.post(`${targetUrl}albums/${albumData.fugaId}/delivery_instructions/takedown`, dspsIds));
  if (errorTakenDownAlbum) {
    dispatch(createBackendError(errorTakenDownAlbum));
    writeCloudLog(`Error TAKEN_DOWN to DSPs, album with name: ${albumData.title} and UPC: ${albumData.upc}
    , in fuga, ownerEmail: ${albumData.ownerEmail}`, { albumData, dspsIds }, errorTakenDownAlbum, "error");
    return "ERROR";
  }
}

// export const rearrengePositionsFuga = async (tracksData, dispatch) => {
//   let traksIdsAndPositions = [];
//   let albumId = tracksData.length > 0 ? tracksData[0].albumFugaId : "";
//   tracksData.forEach(trackData => traksIdsAndPositions.push({ trackId: trackData.fugaId, newPosition: trackData.position }));

//   let [errorRearrengingPositions, result] = await to(axios.put(`${targetUrl}albums/${albumId}/rearrenge`,
//     { rearrengeInstructions: traksIdsAndPositions }));
//   if (errorRearrengingPositions) {
//     dispatch(createBackendError(errorRearrengingPositions));
//     writeCloudLog("Error rearrenging album positions in fuga", { tracksData, albumId }, errorRearrengingPositions, "error");
//     return "ERROR";
//   }
//   return result;
// }

export const sendAppleRejection = async (ownerAlbumEmail, ownerName, albumTitle, albumUPC, artistName, appleObs, dispatch) => {
  const [errorSendingEmail] = await to(axios.post(`${targetUrl}albums/apple-rejection-notification`, { ownerAlbumEmail, ownerName, albumTitle, albumUPC, artistName, appleObs }));
  if (errorSendingEmail) {
    dispatch(createBackendError(errorSendingEmail));
    writeCloudLog(`Error sending APPLE REJECTION email, album with name: ${albumTitle} and UPC: ${albumUPC}
    ownerEmail: ${ownerAlbumEmail}`, { albumTitle, albumUPC }, errorSendingEmail, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const sendInfractionCopyright = async (infractionState, ownerAlbumEmail, albumTitle, albumUPC, artistName, infractionObs, dispatch) => {
  const [errorSendingEmail] = await to(axios.post(`${targetUrl}albums/infraction-notification`, { infractionState, ownerAlbumEmail, albumTitle, albumUPC, artistName, infractionObs }));
  if (errorSendingEmail) {
    dispatch(createBackendError(errorSendingEmail));
    writeCloudLog(`Error sending COPYRIGHT INFRACTOIN NOTIFICATION email, album with name: ${albumTitle} and UPC: ${albumUPC}
    ownerEmail: ${ownerAlbumEmail}`, { albumTitle, albumUPC }, errorSendingEmail, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

// ======================================TRACKS=============================================\\

export const getTrackFugaById = async (fugaAssetId, dispatch) => {
  let [errorGettingTrackInFuga, trackFromThirdWebApi] = await to(axios.get(`${targetUrl}tracks/${fugaAssetId}`));
  if (errorGettingTrackInFuga) {
    dispatch(createBackendError(errorGettingTrackInFuga));
    writeCloudLog(`Error getting track in fuga with ID ${fugaAssetId}`, fugaAssetId, errorGettingTrackInFuga, "error");
    return "ERROR";
  }
  if (!trackFromThirdWebApi.data.response.id) return "NOT_EXISTS";
  return trackFromThirdWebApi.data.response;
}

export const getTrackFugaByFieldValue = async (fieldValue, dispatch) => {
  let [errorGettingTrackInFuga, trackFromThirdWebApi] = await to(axios.get(`${targetUrl}tracks?search=${fieldValue}`));
  if (errorGettingTrackInFuga) {
    dispatch(createBackendError(errorGettingTrackInFuga));
    writeCloudLog(`Error getting track in fuga with value ${fieldValue}`, fieldValue, errorGettingTrackInFuga, "error");
    return "ERROR";
  }
  if (trackFromThirdWebApi.data.response.asset.length === 0) return "NOT_EXISTS";
  return trackFromThirdWebApi.data.response.asset;
}

export const editTrackFugaById = async (newFormData, fugaIdTrack, dispatch) => {
  let [errorEditingTrackInFuga] = await to(axios.put(`${targetUrl}tracks/${fugaIdTrack}`, newFormData));
  if (errorEditingTrackInFuga) {
    dispatch(createBackendError(errorEditingTrackInFuga));
    writeCloudLog(`Error editing track in fuga with id ${fugaIdTrack}`, errorEditingTrackInFuga, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const createTrackAssetFuga = async (formDataTrack, ownerEmail, albumFugaId, dispatch) => {
  let [errorUploadingTrackInThirdWebApi, trackFromThirdWebApi] = await to(axios.post(`${targetUrl}tracks/new`, formDataTrack));
  if (errorUploadingTrackInThirdWebApi) {
    dispatch(createBackendError(errorUploadingTrackInThirdWebApi));
    writeCloudLog(`Error creating track asset in fuga with album fugaId ${albumFugaId}, ownerEmail: ${ownerEmail}`, copyFormDataToJSON(formDataTrack), errorUploadingTrackInThirdWebApi, "error");
    return "ERROR";
  }
  return trackFromThirdWebApi;
}

export const uploadTrackFileFuga = async (formDataTrackFile, ownerEmail, onUploadProgress, albumFugaId, retry, dispatch) => {
  let [errorUploadingTrackInThirdWebApi, trackFromThirdWebApi] = await to(axios.post(`${targetUrl}tracks/audio_file`, formDataTrackFile, { onUploadProgress }));
  if (errorUploadingTrackInThirdWebApi) {
    dispatch(createBackendError(errorUploadingTrackInThirdWebApi));
    await writeCloudLog(`Error uploading track file (RETRY : ${retry}) in fuga with album fugaId ${albumFugaId}, ownerEmail: ${ownerEmail}`, copyFormDataToJSON(formDataTrackFile), errorUploadingTrackInThirdWebApi, "error");
    return "ERROR";
  }
  return trackFromThirdWebApi;
}

export const createPersonsFuga = async rawDataPeople => {
  if (rawDataPeople.length === 0) return [];
  let [errorUploadingPersonsInThirdWebApi, personsFromThirdWebApi] = await to(axios.post(`${targetUrl}people/addAll`, rawDataPeople));
  if (errorUploadingPersonsInThirdWebApi) {
    writeCloudLog("Error creating person in fuga", copyFormDataToJSON(rawDataPeople), errorUploadingPersonsInThirdWebApi, "error");
    return "ERROR";
  }
  let personsWithId = personsFromThirdWebApi.data.response;
  return personsWithId;
}

export const createCollaboratorFuga = async (collaborator, ownerEmail) => {
  if (!collaborator.person || collaborator.role.length === 0) {
    writeCloudLog(`Error creating collaborator in fuga, with email: ${ownerEmail}`, collaborator, "COLLABORATORS: EMPTY PERSON OR ROLES", "error");
    return "ERROR";
  }

  let rawDataCollaborator = { person: collaborator.person, role: collaborator.role };
  let [errorAttachingCollaboratorInThirdWebApi, collaboratorFromThirdWebApi] = await to(axios.post(`${targetUrl}tracks/${collaborator.trackFugaId}/contributors`, rawDataCollaborator));
  if (errorAttachingCollaboratorInThirdWebApi) {
    writeCloudLog(`Error creating collaborator in fuga, with email: ${ownerEmail}`, collaborator, errorAttachingCollaboratorInThirdWebApi, "error");
    return "ERROR";
  }
  let personsWithId = collaboratorFromThirdWebApi.data.response.id;
  return personsWithId;
}

export const deleteCollaboratorFugaByIdAndTrack = async (collFugaId, trackFugaId, ownerEmail, dispatch) => {
  if (!collFugaId || !trackFugaId) {
    writeCloudLog(`Error deleting collaborator in fuga, with email: ${ownerEmail}`, { collFugaId, trackFugaId }, "COLLABORATORS: ERROR DELETING", "error");
    return "ERROR";
  }
  let [errorDeletingCollaboratorInFuga] = await to(axios.delete(`${targetUrl}tracks/${trackFugaId}/contributors/${collFugaId}`));
  if (errorDeletingCollaboratorInFuga) {
    dispatch(createBackendError(errorDeletingCollaboratorInFuga));
    writeCloudLog(`Error deleting collaborator in fuga, with email: ${ownerEmail}`, { collFugaId, trackFugaId }, errorDeletingCollaboratorInFuga, "error");
    return "ERROR";
  }
  return "SUCCESS";
}
// ======================================USERS=============================================\\

export const editUserDataAndCredentialsFS = async (newUserData, dispatch) => {
  let [errorChangingUserDataAndCreds] = await to(axios.put(`${targetUrl}firebase/user/password/${newUserData.email}`,
    { password: newUserData.password }));
  if (errorChangingUserDataAndCreds) {
    dispatch(createBackendError(errorChangingUserDataAndCreds));
    writeCloudLog(`Error cambiando las credenciales del Usuario ${newUserData.email}`, newUserData, errorChangingUserDataAndCreds, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const editNotLoggedUserDataFS = async (userId, newUserData, dispatch) => {
  let [errorChangingUserData] = await to(axios.put(`${targetUrl}firebase/user/id/${userId}`, newUserData));
  if (errorChangingUserData) {
    dispatch(createBackendError(errorChangingUserData));
    writeCloudLog(`Error cambiando datos del Usuario ${userId} que no esta logueado`, newUserData, errorChangingUserData, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

// Cambia las credenciales del Usuario, y actualiza sus elementos, en FS y en FUGA (extras).
export const editUserCredentialsAndElementsFS = async (oldEmail, newEmail, newPassword, dispatch) => {
  let [errorChangingUserDataAndCreds] = await to(axios.put(`${targetUrl}users/change-email`,
    { oldEmail, newEmail, newPassword }));
  if (errorChangingUserDataAndCreds) {
    dispatch(createBackendError(errorChangingUserDataAndCreds));
    writeCloudLog(`Error cambiando las credenciales del Usuario ${oldEmail}`,
      { oldEmail, newEmail, newPassword }, errorChangingUserDataAndCreds, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

export const createLoginOTPDocServer = async (userId, dispatch) => {
  let [errorPost, loginOTPDoc] = await to(axios.post(`${targetUrl}firebase/user/otp/${userId}`));
  if (errorPost) {
    dispatch(createBackendError(errorPost));
    writeCloudLog(`Error creating OTP payout: ${userId} for userId: ${userId}`);
    return "ERROR";
  }

  if (!loginOTPDoc.data || loginOTPDoc.empty) return "NOT_EXISTS";
  return loginOTPDoc?.data.response || "ERROR";
}

export const checkLoginOTPDocServer = async (userId, otp, dispatch) => {
  let [errorGet, loginOTPDoc] = await to(axios.get(`${targetUrl}firebase/user/otp/${userId}/check?otp=${otp}`));
  if (errorGet) {
    dispatch(createBackendError(errorGet));
    writeCloudLog(`Error getting OTP login: ${userId}`);
    return "ERROR";
  }

  if (!loginOTPDoc.data || loginOTPDoc.empty) return "NOT_EXISTS";
  return loginOTPDoc?.data.response || "ERROR";
}

//======================================================VARIAS========================================================================\\

export const createSubgenreFuga = async (subgenreName, dispatch) => {
  const [errorCreatingSubgenre, resultWIthFugaId] = await to(axios.post(`${targetUrl}miscellaneous/subgenres`, { name: subgenreName }));
  if (errorCreatingSubgenre) {
    dispatch(createBackendError(errorCreatingSubgenre));
    writeCloudLog("Error creating subgenre in fuga", subgenreName, errorCreatingSubgenre, "error");
    return "ERROR";
  }
  return resultWIthFugaId.data.response;
}

//=====================================================ROYALTIES=======================================================================\\

export const getRoyaltiesForTableView = async (userId, fieldName, fieldValue, limit, offset, isAdmin, dispatch) => {
  const [errorGettingRoyalties, royaltiesResponse] = await to(axios.post(`${targetUrl}royalties/search`,
    { userId, fieldName, fieldValue, limit, offset, isAdmin }));
  if (errorGettingRoyalties) {
    dispatch(createBackendError(errorGettingRoyalties));
    writeCloudLog("Error getting royalties from DB", { fieldName, fieldValue, limit, offset }, errorGettingRoyalties, "error");
    return "ERROR";
  }

  let result = royaltiesResponse.data.response;
  return { count: result.total || 0, rows: result.royalties || [] };
}

export const getTotalTaxesFrom = async (userData, isAdmin, dispatch) => {

  if (Number.isFinite(userData?.taxes)) return { totalTaxes: userData.taxes };
  else {
    const [errorGettingDoc, userDoc] = await to(getUserDocFS(userData.id, dispatch));
    if (errorGettingDoc || !userDoc.data()) return "ERROR";
    return { totalTaxes: userDoc.data().taxes || 0 };
  }
}

export const queryRoyaltiesWithOp = async (userId, fieldName, fieldValue, op, fieldOp, groupBy, isAdmin, dispatch) => {
  if (!isAdmin && fieldValue.length === 0) return 0;
  if (fieldName === 'upc') fieldValue = Array.isArray(fieldValue)
    ? fieldValue.map(value => String(value)) : [String(fieldValue)];
  const [errorGettingRoyalties, royaltiesResponse] = await to(axios.post(`${targetUrl}royalties/query-with-op`,
    { userId, fieldName, fieldValue, op, fieldOp, groupBy, currency: 'USD', isAdmin }));
  if (errorGettingRoyalties) {
    dispatch(createBackendError(errorGettingRoyalties));
    writeCloudLog("Error getting royalties from DB", { fieldName, fieldValue, op, fieldOp, groupBy }
      , errorGettingRoyalties, "error");
    return "ERROR";
  }
  return royaltiesResponse.data.response;
}

export const getAccountingGroupedByForTableView = async (userId, groupByProp, fieldName, fieldValue, isAdmin, dispatch) => {
  if (fieldName === 'upc') fieldValue = fieldValue.map(value => String(value));
  const [errorGettingAccounting, accountingResponse] = await to(axios.post(`${targetUrl}royalties/accounting-groupBy/${groupByProp}`,
    { userId, fieldName, fieldValue, isAdmin }));
  if (errorGettingAccounting) {
    dispatch(createBackendError(errorGettingAccounting));
    writeCloudLog("Error getting royalties from DB", { groupByProp }, errorGettingAccounting, "error");
    return "ERROR";
  }
  return accountingResponse.data.response;
}

export const hideOrShowRoyaltiesToUser = async (upc, months, showOrNot, dispatch) => {
  let monthAffected = new Date().getMonth() + 1;
  monthAffected = monthAffected < 10 ? `0${monthAffected}` : monthAffected;
  let whereClause = { upc, reportedMonth: `2022-${monthAffected}-01` };
  let newShowToUserValue = showOrNot === 'show' ? 1 : 0;
  const [errorHidingRoyalties] = await to(axios.put(`${targetUrl}royalties/show-royalty-to-user`,
    { whereClause, newShowToUserValue }));
  if (errorHidingRoyalties) {
    dispatch(createBackendError(errorHidingRoyalties));
    writeCloudLog("Error hiding royalties from DB", { upc, months }, errorHidingRoyalties, "error");
    return "ERROR";
  }
  return "SUCCESS";
}

//=======================================================Dinero Disponible==============================================\\
export const getUserAvailableMoney = async (user, dispatch) => {
  const showAsAdmin = false;
  if (!user.id) return "...";

  const albumsFinded = await dispatch(getAlbumsByFieldRedux('ownerId', user.id, 500));
  if (albumsFinded === "ERROR") return "ERROR";
  if (albumsFinded === "EMPTY") return { payed: "0", royalties: "0", available: "0", lastRequest: "no tiene solicitudes", taxesUsd: "0" };
  const albumsUpcs = albumsFinded.map(albumData => albumData.upc);

  let [royalties, taxesUsd, { payed, lastRequest }] = await Promise.all([
    await queryRoyaltiesWithOp(user.id, 'upc', albumsUpcs, 'sum', 'ourNetRevenue', [], showAsAdmin, dispatch),
    await getTotalTaxesFrom(user, showAsAdmin, dispatch),
    await getLastPayoutForUser(user.email, dispatch)
  ])

  royalties = royalties.length > 0 ? royalties[0].revenuesUSD : "0";
  let available = Number(parseFloat(royalties) - parseFloat(payed) - parseFloat(taxesUsd.totalTaxes));
  royalties = Number(truncateFloat(royalties, 2, '.'));
  taxesUsd = Number(truncateFloat(taxesUsd.totalTaxes, 3, '.'));
  available = Number(truncateFloat(available, 2, '.'));
  payed = Number(truncateFloat(payed, 2, '.')) || 0;
  return { payed, royalties, available, lastRequest, taxesUsd };
}

//=======================================================PAYOUTS=========================================================\\

export const getPayoutsForTableView = async (field, value, limit, offset, isAdmin, dispatch) => {
  let orderClause = "lastUpdateTS.DESC";
  // Si no es admin y por alguna razon el value (osea el mail del user da undefined o algo que castea a false, no permito que traiga todo)
  if (!value && !isAdmin) return { count: 0, payouts: [] };
  let whereClause = JSON.stringify(value ? { [field]: value } : isAdmin ? {} : "");
  let timestamp = new Date().getTime();
  let queryParams = `?limit=${limit}&offset=${offset}&order=${orderClause}&where=${whereClause}&timestamp=${timestamp}`;

  const [errorGettingPayouts, payoutsResponse] = await to(axios.get(`${targetUrl}payouts/${queryParams}`));
  if (errorGettingPayouts) {
    dispatch(createBackendError(errorGettingPayouts));
    writeCloudLog("Error getting payouts from DB", { field, value, limit, offset }, errorGettingPayouts, "error");
    return "ERROR";
  }
  return { count: payoutsResponse.data.response.total, payouts: payoutsResponse.data.response.payouts };
}

export const getPayoutsAccountingForTableView = async (field, value, groupBy, orderByProp, isAdmin, dispatch) => {
  if (!isAdmin && !value) return { accWithOutPending: [], accOnlyPending: [] }
  let orderClause = `${orderByProp.field}.${orderByProp.order}`;
  let whereClause = JSON.stringify(value ? { [field]: value } : {});
  let ops = JSON.stringify([{ op: "sum", field: "transferTotalUsd", name: "totalPayed" },
  { op: "count", field: "ownerEmail", name: "cantPayouts" },
  { op: "max", field: "requestDate", name: "lastPayAskedDay" }]);
  let attNoOps = JSON.stringify([{ name: groupBy }]);
  let timestamp = new Date().getTime();

  let queryParams = `?order=${orderClause}&where=${whereClause}&groupBy=${groupBy}&ops=${ops}&attributes=${attNoOps}&timestamp=${timestamp}`;

  const [errorGettingPayouts, payoutsResponse] = await to(axios.get(`${targetUrl}payouts/accounting/${queryParams}`));
  if (errorGettingPayouts) {
    dispatch(createBackendError(errorGettingPayouts));
    writeCloudLog("Error getting payouts from DB", { field, value, groupBy }, errorGettingPayouts, "error");
    return "ERROR";
  }
  return payoutsResponse.data.response;
}

export const getLastPayoutForUser = async (userEmail, dispatch) => {
  let timestamp = new Date().getTime();
  let [errorGettingLastPayout, lastPayout] = await to(axios.get(`${targetUrl}payouts/totalPayed/${userEmail}?timestamp=${timestamp}`));
  if (errorGettingLastPayout) {
    dispatch(createBackendError(errorGettingLastPayout));
    writeCloudLog(`Error getting last payout for ${userEmail} from DB`, userEmail, errorGettingLastPayout, "error");
    return "ERROR";
  }
  let responseData = lastPayout.data.response;
  if (!responseData) return { payed: 0, lastRequest: "no tienes solicitudes." };

  return { payed: responseData.historicTotalUsd, lastRequest: responseData.requestDate, taxesUsd: responseData.taxesUsd };
}

export const createUserPayoutInDbAndFS = async (newPayout, isAdmin, dispatch) => {
  let [errorCreatingPayout, newPayoutResponse] = await to(axios.post(`${targetUrl}payouts/`,
    { payoutRecord: newPayout, sendNotification: isAdmin ? false : true }));
  if (errorCreatingPayout) {
    dispatch(createBackendError(errorCreatingPayout));
    writeCloudLog(`Error creating payout for ${newPayout.ownerEmail} from DB`, newPayout, errorCreatingPayout, "error");
    return "ERROR";
  }
  if (!newPayoutResponse.data.response) return "ERROR";
  return newPayoutResponse.data.response;
}

export const updateUserPayoutInDbAndFS = async (updatedPayout, sendNotification, dispatch) => {
  let [errorCreatingPayout, newPayoutResponse] = await to(axios.put(`${targetUrl}payouts/`,
    { payoutRecord: updatedPayout, sendNotification }));
  if (errorCreatingPayout) {
    dispatch(createBackendError(errorCreatingPayout));
    writeCloudLog(`Error updating payout for ${updatedPayout.ownerEmail} from DB`, updatedPayout, errorCreatingPayout, "error");
    return "ERROR";
  }
  if (!newPayoutResponse.data.response) return "ERROR";
  return newPayoutResponse.data.response;
}

export const deletePayoutInDbAndFS = async (payoutId, dispatch) => {
  let [errorDeletingPayout, deleteResponse] = await to(axios.delete(`${targetUrl}payouts/${payoutId}`,))
  if (errorDeletingPayout) {
    dispatch(createBackendError(errorDeletingPayout));
    writeCloudLog(`Error deleting payout for ${payoutId} from DB`, payoutId, errorDeletingPayout, "error");
    return "ERROR";
  }
  if (!deleteResponse.data.response) return "ERROR";
  return deleteResponse.data.response;
}

export const createPayoutOTPDocServer = async (payoutId, userId, dispatch) => {
  let [errorPost, payoutOTPDoc] = await to(axios.post(`${targetUrl}payouts/otp/${payoutId}`, { ownerId: userId }));
  if (errorPost) {
    dispatch(createBackendError(errorPost));
    writeCloudLog(`Error creating OTP payout: ${payoutId} for userId: ${userId}`);
    return "ERROR";
  }

  if (!payoutOTPDoc.data || payoutOTPDoc.empty) return "NOT_EXISTS";
  return payoutOTPDoc?.data.response || "ERROR";
}

export const checkPayoutOTPDocServer = async (payoutId, otp, dispatch) => {
  let [errorGet, payoutOTPDoc] = await to(axios.get(`${targetUrl}payouts/otp/${payoutId}/check?otp=${otp}`));
  if (errorGet) {
    dispatch(createBackendError(errorGet));
    writeCloudLog(`Error getting OTP payout: ${payoutId}`);
    return "ERROR";
  }

  if (!payoutOTPDoc.data || payoutOTPDoc.empty) return "NOT_EXISTS";
  return payoutOTPDoc?.data.response || "ERROR";
}
//=============================================================TRACKS/UPLOADS==========================================================================\\

export const getTrackUploadUuidFuga = async (trackAssetFugaId, userEmail, dispatch) => {
  let [errorGettingUploadUuid, uuidFromFuga] = await to(axios.post(`${targetUrl}tracks/${trackAssetFugaId}/fuga-upload-uuid`));
  if (errorGettingUploadUuid) {
    dispatch(createBackendError(errorGettingUploadUuid));
    await writeCloudLog(`Error getting upload track uuid for asset ${trackAssetFugaId}, user: ${userEmail}`, trackAssetFugaId, errorGettingUploadUuid, "error");
    return "ERROR";
  }
  return uuidFromFuga.data.response.id;
}

export const getUploadFileContext = async (fileName, fileSize, userEmail, dispatch) => {

  let [errorGettingContext, fileContextResponse] = await to(axios.get(`${targetUrl}tracks/upload/status`, {
    headers: { "x-file-name": fileName, "file-size": fileSize },
  }))
  if (errorGettingContext) {
    dispatch(createBackendError(errorGettingContext));
    writeCloudLog(`Error getting file context for ${fileName} from FUGA, user: ${userEmail}`, fileName, errorGettingContext, "error");
    return "ERROR";
  }
  return fileContextResponse.data;
};

export const uploadOneChunk = async (fileId, formDataChunk, userEmail, retry, dispatch) => {

  // ACA VA portUrl
  let [errorUploadingOneChunk, uploadChunkResponse] = await to(axios.post(`${targetUrl}tracks/upload/files`, formDataChunk));
  if (errorUploadingOneChunk) {
    dispatch(createBackendError(errorUploadingOneChunk));
    writeCloudLog(`Error uploading one chunk to ${fileId} from FUGA (RETRY: ${retry}), user: ${userEmail}`, fileId, errorUploadingOneChunk, "error");
    return "ERROR";
  }
  return uploadChunkResponse.data.response;
};

export const finishUpload = async (fileId, fileName, fugaUuid, userEmail, dispatch) => {
  let [errorFinishingUpload, finishResponse] = await to(axios.post(`${targetUrl}tracks/upload/complete/${fugaUuid}`, { fileName, fileId }));
  if (errorFinishingUpload) {
    dispatch(createBackendError(errorFinishingUpload));
    writeCloudLog(`Error finishing file upload for track ${fileId} from FUGA, user: ${userEmail}`, fileId, errorFinishingUpload, "error");
    return "ERROR";
  }
  if (finishResponse.data.status === "SUCCESSFULLY_UPLOADED") return "SUCCESS";
  else return "ERROR";
};


//=====================================================================PAYMENTS===================================================================\\

export const updateMonthStatsServer = async (monthNumber, year, dispatch) => {
  let [errorUpdating] = await to(axios.put(`${targetUrl}payments/stats/set?month=${monthNumber}&year=${year}`));
  if (errorUpdating) {
    dispatch(createBackendError(errorUpdating));
    writeCloudLog(`Error updating stats report (${monthNumber}/${year}) of payments`);
    return "ERROR";
  }
  return "SUCCESS";
}

//================================================================RESET-PASSWORD===================================================================\\

export const getResetPasswordLinkServer = async (code, dispatch) => {

  let [errorGet, resetPassDoc] = await to(axios.get(`${targetUrl}firebase/user/reset-password/${code}?${new Date().getTime()}`));
  if (errorGet) {
    dispatch(createBackendError(errorGet));
    writeCloudLog(`Error getting password reset link: ${code}`);
    return "ERROR";
  }

  if (!resetPassDoc.data || resetPassDoc.empty) return "NOT_EXISTS";
  return resetPassDoc?.data.response || "ERROR";
}

export const checkOTPResetPasswordLinkServer = async (code, otp, dispatch) => {

  let [errorGet, resetPassDoc] = await to(axios.get(`${targetUrl}firebase/user/reset-password/otp/check?code=${code}&otp=${otp}`));
  if (errorGet) {
    dispatch(createBackendError(errorGet));
    writeCloudLog(`Error getting password reset link: ${code}`);
    return "ERROR";
  }

  if (!resetPassDoc.data || resetPassDoc.empty) return "NOT_EXISTS";
  return resetPassDoc?.data.response || "ERROR";
}

export const updateResetPasswordLinkServer = async (userEmail, newInfo, dispatch) => {
  let [errorUpdate, resetPassDoc] = await to(axios.put(`${targetUrl}firebase/user/reset-password/${userEmail}`, { newInfo }));
  if (errorUpdate) {
    dispatch(createBackendError(errorUpdate));
    writeCloudLog(`Error udpating password reset link: ${userEmail}`);
    return "ERROR";
  }

  if (!resetPassDoc.data || resetPassDoc.empty) return "NOT_EXISTS";
  return resetPassDoc.data;
}

export const createResetPasswordLinkServer = async (userEmail, dispatch) => {
  let [errorPost, resetPassDoc] = await to(axios.post(`${targetUrl}firebase/user/reset-password/${userEmail}`));
  if (errorPost) {
    dispatch(createBackendError(errorPost));
    writeCloudLog(`Error creating password reset link: ${userEmail}`);
    return "ERROR";
  }

  if (!resetPassDoc.data || resetPassDoc.empty) return "NOT_EXISTS";
  console.log("RESET PASS DOC:", resetPassDoc);
  return resetPassDoc?.data.response || "ERROR";
}

// ==================================================CONTACT SUPPORT=============================================================\\

export const mailingOutSupport = async (name, email, msg, dispatch) => {
  const subject = `Consulta (Externa) | ${email} | ${name}`;
  let [errorSendSupport, responseSendSupport] = await to(axios.post(`${targetUrl}emails/support`, { subject, msg }));
  if (errorSendSupport || responseSendSupport?.data?.response !== "OK") {
    dispatch(createBackendError(errorSendSupport));
    writeCloudLog(`Error sending support msg from user: ${email}`);
    return "ERROR";
  }
  return responseSendSupport?.data?.response;
}