import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Typography, Link, List, ListItem, Button } from '@mui/material';
import { Image } from 'mui-image';
import { ytRedColor } from 'variables/colors';
import CardHeader from 'components/Card/CardHeader';
import TextFieldWithInfo from 'components/TextField/TextFieldWithInfo';
import { useDispatch, useSelector } from 'react-redux';
import { artistEditOneFieldRedux } from 'redux/actions/ArtistsActions';
import SimpleReactValidator from "simple-react-validator";
import Danger from 'components/Typography/Danger';
import { artistsWhoCanAddYtChannel, validateOACId } from 'utils/artists.utils';
import { toWithOutError } from 'utils';
import { ARTIST_OAC_REQUESTED } from 'variables/artist.variables';
import SuccessDialog from 'components/Dialogs/SuccessDialog';
import { oacSuccessDialogSubtitle, oacSuccessDialogTitle } from 'utils/textToShow.utils';
import { getOnlineAlbumsFromArtistId } from 'utils/albums.utils';
import { oacNoAlbumsAvailableText } from '../../utils/textToShow.utils';

const Youtube4Artists = () => {

  const dispatch = useDispatch();
  const userData = useSelector(store => store.userData);
  const albums = useSelector(store => store.albums.albums);
  const myArtists = useSelector(store => store.artists.artists);
  const validator = useRef(new SimpleReactValidator());

  const [youtubeOAC, setYoutubeOAC] = useState("");
  const [artistChoosen, setArtistChoosen] = useState({ name: "" });
  const [albumChoosen, setAlbumChoosen] = useState({ title: "" });
  const [ytIdIsValid, setYtIdIsValid] = useState(true);
  const [myActiveArtists, setMyActiveArtists] = useState(myArtists);
  const [artistOnlineAlbums, setArtistOnlineAlbums] = useState([]);
  const [actionState, setActionState] = useState('none');
  const [noAlbumsFromArtist, setNoAlbumFromArtist] = useState(false);

  useEffect(() => {
    let artistsCanAddYtChannel = artistsWhoCanAddYtChannel(userData.id, myArtists, albums);
    if (artistsCanAddYtChannel.length === 0) { setAtLeastOneArtistCan(false); return; }
    setMyActiveArtists(artistsCanAddYtChannel);
  }, [])

  useEffect(() => {
    if (artistChoosen.id) {
      let albumsOnlineFromArtist = getOnlineAlbumsFromArtistId(albums, artistChoosen.id, userData.id);
      setArtistOnlineAlbums(albumsOnlineFromArtist);
      if (albumsOnlineFromArtist.length === 0) setNoAlbumFromArtist(true);
    }
  }, [artistChoosen])

  const handlerArtistChoose = event => {
    let artistResult = myArtists.find(artist => artist.name === event.target.value) || "NOT_FOUND";
    setArtistChoosen(artistResult);
  };

  const handlerAlbumChoose = event => {
    let albumResult = artistOnlineAlbums.find(album => album.title === event.target.value) || "NOT_FOUND";
    setAlbumChoosen(albumResult);
  };

  const handleChangeYtID = event => {
    if (!validateOACId(event.target.value)) setYtIdIsValid(false);
    else setYtIdIsValid(true);
    if (event.target.value.length <= 24) {
      setYoutubeOAC(event.target.value);
      return;
    }
  }

  const requestOacForArtist = async () => {
    let updateState = await toWithOutError(dispatch(artistEditOneFieldRedux(
      artistChoosen, { state: ARTIST_OAC_REQUESTED, ytOAC: youtubeOAC }, userData.email, false, true)));
    if (updateState === "ERROR") { console.log("error"); return; }
    setActionState("success");
  }

  return (
    <Grid item xs={12} padding={10} sx={{ textAlign: "left" }}>

      <SuccessDialog isOpen={actionState === 'success'} title={oacSuccessDialogTitle} contentTexts={oacSuccessDialogSubtitle}
        handleClose={() => setActionState('none')} successImageSource="/images/success.jpg" size="sm" />

      <CardHeader color="primary" style={{ background: ytRedColor, textAlign: "center" }}>
        <Typography sx={cardTitleWhiteStyles}>YouTube para Artistas</Typography>
        <p style={cardCategoryWhiteStyles}>Bienvenido a la página de YouTube para Artistas. Aquí encontrarás la información necesaria para obtener el ID de tu canal de YouTube.</p>
      </CardHeader>

      <Box component="main" sx={{ my: 3 }}>
        <Box component="section" sx={{ mb: 3 }}>
          <Typography paddingTop={2} sx={{ fontSize: fontSizeText }}>
            ❖ Un <strong>
              <Link target="_blank" href="https://support.google.com/youtube/answer/7336634?hl=es#zippy=%2Ccriterios-y-requisitos-del-programa%2Cc%C3%B3mo-solicitar-un-canal-oficial-de-artista" sx={styleForLinks}>
                Canal Oficial de Artista (OAC · Official Artist Channel)
              </Link>
            </strong> de YouTube aparece con una pequeña corchea {`(♪)`} al lado del nombre de Artista, una vez verificado.
          </Typography>
        </Box>

        <Box>
          <Image
            style={imageStyle}
            alt="youtube-oac-ex"
            duration={30}
            src={"https://firebasestorage.googleapis.com/v0/b/laflota-dev.appspot.com/o/exampleImages%2Fyoutube-oac.jpg?alt=media&token=d30649d4-e484-4c94-827a-d7dce75c38d0"}
          />
        </Box>

        <Box paddingTop={2} component="section" sx={{ mb: 3 }}>
          <Typography sx={{ fontSize: fontSizeText }}>
            Si no tienes un <b>OAC</b>, por defecto los lanzamientos van a un canal tópico {`(Topic o Tema)`} generado automáticamente con el nombre de Artista o a un canal{' '}
            <Link target="_blank" href="https://www.youtube.com/channel/UCWhkHh4tl-ktl3fsuN_zEdA/videos" sx={styleForLinks}>
              Various Artists
            </Link>
            {' '} de YouTube {' '}→
            <Link target="_blank" href="https://www.youtube.com/watch?v=aBdJEHoTxlw" sx={styleForLinks}>
              ¿Por qué van al Canal Various Artists?
            </Link>
          </Typography>
          <Typography paddingTop={2} sx={{ fontSize: fontSizeText }}>
            ❖ Puedes publicar tu material en tu canal de YouTube siempre y cuando ya sea un Canal Oficial de Artista.
          </Typography>
        </Box>
        <Box component="section" sx={{ mb: 3 }}>
          <Typography variant="h5" component="h2">
            Requisitos para un Canal Oficial de Artista
          </Typography>
          <Typography sx={{ fontSize: fontSizeText }}>
            Para tener un Canal Oficial de Artista, debes reunir los siguientes requisitos:
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 4 }}>
            <ListItem sx={styleListItem}>
              El nombre del canal debe coincidir 100% con tu nombre de artista de La Flota, por favor verifica aquí la
              <Link target="_blank" href="https://support.google.com/youtube/answer/3250431?hl=es-419" sx={styleForLinks}>
                {' '}guía para obtener el ID del canal correctamente.
              </Link>
            </ListItem>
            <ListItem sx={styleListItem}>
              Tener un canal propio y operado de YouTube de un artista solista o una banda o proyecto que esté dedicado a su música.
            </ListItem>
            <ListItem sx={styleListItem}>
              El canal del artista Contar debe tener al menos un video relacionado (online) con un lanzamiento hecho en La Flota.
            </ListItem>
            <ListItem sx={styleListItem}>
              Tu canal debe cumplir todas las políticas de YouTube, incluidas las Normas de la Comunidad, los Términos del Servicio y las políticas de derechos de autor.
            </ListItem>
          </List>
        </Box>
        <Grid container textAlign="center" justifyContent="center">
          <Grid item lg={6} md={8} xs={12} padding={1}>
            <Box component="section" id="request" sx={{ mb: 3 }}>
              <Typography variant="h4" component="h2">
                Solicitar Canal Oficial de Artista
              </Typography>

              <Typography paddingTop={2} sx={{ fontSize: fontSizeText, textAlign: 'left' }}>
                Si todavía no tienes lanzamientos Online en La Flota con el Artista que quieres vincular al canal de YouTube no podrás solicitarlo todavía.
              </Typography>

              {myActiveArtists.length > 0 && <TextFieldWithInfo
                name="artist"
                fullWidth
                autoFocus
                required
                select
                label="Artista"
                value={artistChoosen.name}
                onChange={handlerArtistChoose}
                helperText="Selecciona al Artista que quieres vincular con el canal de YouTube"
                selectItems={myActiveArtists}
                selectKeyField="id"
                selectValueField="name"
                validatorProps={{ restrictions: 'required', message: "Debes seleccionar al Artista.", validator: validator }}
              />}

              {(artistOnlineAlbums.length > 0 ||noAlbumsFromArtist) && <TextFieldWithInfo
                name="album"
                fullWidth
                autoFocus
                required
                select
                label="Album del Artista en el Canal de YT"
                value={albumChoosen.title}
                onChange={handlerAlbumChoose}
                helperText="Selecciona el Album, que esté Online en el canal del artista que quieres vincular"
                selectItems={artistOnlineAlbums}
                selectKeyField="id"
                selectValueField="title"
                validatorProps={{ restrictions: 'required', message: "Debes seleccionar al menos un Album relacionado con el artista.", validator: validator }}
              />}
              {noAlbumsFromArtist && <Danger>{oacNoAlbumsAvailableText}</Danger>}

              <TextFieldWithInfo
                name="youtubeOAC"
                fullWidth
                disabled={!(artistChoosen.id && albumChoosen.id)}
                label="ID del Canal de YT (Formato: UCxxxxxxxxxxxxxxxxxxxxxx)"
                value={youtubeOAC}
                onChange={handleChangeYtID}
                helperText="Ingresa el ID del Canal de YouTube. Formato: UCxxxxxxxxxxxxxxxxxxxxxx"
              />
              {!ytIdIsValid && <Danger>YouTube Official Artist Channel ID es invalido (Formato: UCxxxxxxxxxxxxxxxxxxxxxx | 24 carácteres).</Danger>}

              <Typography paddingTop={2} sx={{ fontSize: fontSizeText, textAlign: "left" }}>
                Una vez enviada la solicitud, puede demorar algunas semanas en ser verificado. Luego tu material irá directamente allí.
                Puedes leer más al respecto en {' '}
                <Link target="_blank" href="https://artists.youtube.com" sx={styleForLinks}>YouTube for Artists</Link>
              </Typography>
            </Box>
            <Grid item textAlign="center">
              <Button variant="contained" disabled={!(ytIdIsValid && youtubeOAC)} onClick={requestOacForArtist} sx={buttonColorStyle}>
                Solicitar
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Box>
    </Grid>
  );
};

export default Youtube4Artists;

const cardCategoryWhiteStyles = { color: "rgba(255,255,255,.82)", margin: "0 0 0", fontSize: "18px", fontWeight: "400" }
const cardTitleWhiteStyles = {
  color: "rgba(255,255,255,255)", marginTop: "0px", minHeight: "auto", fontWeight: "300px", fontSize: "40px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", marginBottom: "3px", textDecoration: "none"
}
const buttonColorStyle = { bgcolor: ytRedColor, '&:hover': { bgcolor: ytRedColor }, width: '15em' };
const imageStyle = { width: "50%", height: "50%", borderRadius: "2em" };
const fontSizeText = "18px";
const styleForLinks = { textDecoration: 'none', color: '#007bff', '&:hover': { textDecoration: 'underline' } };
const styleListItem = { display: 'list-item', fontSize: fontSizeText };
