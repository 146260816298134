import React, { useState, useEffect } from "react";
import {
  Button, Grid, Dialog, DialogTitle, DialogContentText, DialogContent,
  DialogActions, TextField, Typography
} from '@mui/material';
import ProgressButton from 'components/CustomButtons/ProgressButton';
import { albumsEditRedux } from "redux/actions/AlbumsActions";
import { useDispatch } from "react-redux";
import { toWithOutError } from "utils";

const EditAlbumTitleDialog = ({ oldAlbumData, setIsOpen, targetUserEmail, fugaAssets }) => {

  const dispatch = useDispatch();
  // Crear estados para el título y la versión
  const [albumTitle, setAlbumTitle] = useState(oldAlbumData.title || "");
  const [albumVersion, setAlbumVersion] = useState(oldAlbumData.version || "");
  const [buttonState, setButtonState] = useState('none');

  // Detectar cambios en el valor inicial
  useEffect(() => {
    if (oldAlbumData) {
      setAlbumTitle(oldAlbumData.title || "");
      setAlbumVersion(oldAlbumData.version || "");
    }
  }, [oldAlbumData]);

  const handleTitleChange = event => setAlbumTitle(event.target.value);
  const handleVersionChange = event => setAlbumVersion(event.target.value);

  const handleConfirmEdit = async () => {
    const updatedValues = { title: albumTitle, version: albumVersion, };
    setButtonState("loading");
    let editResult = await toWithOutError(
      dispatch(albumsEditRedux(oldAlbumData, updatedValues, targetUserEmail, true, { assets: fugaAssets })));
    if (editResult === "ERROR") { setButtonState("error"); return; }
    setButtonState("success");
    setIsOpen(false);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={open}
      onClose={() => setIsOpen(false)}
    >
      <DialogTitle>
        <Typography sx={{ fontSize: "1.5em", fontWeight: 500 }}>
          Editar título y versión
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Puedes editar el título del lanzamiento, la versión o ambas a la vez.
        </DialogContentText>
        <DialogContentText>
          Recuerda que si el lanzamiento tiene una sola canción, ambas versiones deben ser iguales.
        </DialogContentText>

        {/* Campo para el Título */}
        <TextField
          fullWidth
          required
          label="Título del Álbum"
          value={albumTitle}
          onChange={handleTitleChange}
          margin="normal"
        />

        {/* Campo para la Versión */}
        <TextField
          fullWidth
          label="Versión del Álbum (Opcional)"
          value={albumVersion}
          onChange={handleVersionChange}
          margin="normal"
        />
      </DialogContent>

      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              sx={buttonMainColor}
              fullWidth
              onClick={() => setIsOpen(false)}
            >
              Atrás
            </Button>
          </Grid>

          <Grid item xs={6}>
            <ProgressButton
              textButton={"Confirmar"}
              loading={buttonState === "loading"}
              buttonState={buttonState}
              onClickHandler={handleConfirmEdit}
              buttonFullWidth={true}
              noFab={true}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default EditAlbumTitleDialog;

const buttonMainColor = { backgroundColor: "#9c27b0", '&:hover': { backgroundColor: "#9c27b0" } };
