import React, { useEffect, useState } from 'react';
import { Image as ImageView } from 'mui-image';
import { ButtonBase, Skeleton, Card, Grid, Backdrop, CircularProgress } from '@mui/material';
import ImageInput from "components/Input/ImageInput";
import { useDispatch } from 'react-redux';
import { to, toWithOutError } from 'utils';
import { updateAddingAlbumImageUrlAndCoverRedux, albumsEditRedux, albumsUploadCoverRedux } from 'redux/actions/AlbumsActions';
import { manageAddImageToStorage } from "services/StorageServices";
import { basicSuccess } from "redux/actions/NotificationsHandlerActions";
import { albumCoverHelperText } from 'utils/textToShow.utils';

const ImageInputAlbum = ({ dataAlbum, imageAsUrlData, isEditing, fugaCoverImageId, onImageUploadSuccess }) => {

  const dispatch = useDispatch();

  const [messageForCover, setMessageForCover] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadingImage, setLoadingImage] = useState(false);

  const imageIsValid = img => {
    return img.width >= 3000 && img.height >= 3000 && img.width <= 6000 && img.height <= 6000 && img.width === img.height;
  }

  const onClickEditImage = async event => {
    dispatch(updateAddingAlbumImageUrlAndCoverRedux({ imagenUrl: "", cover: "" }));
    setMessageForCover("");
    let img = new window.Image();
    let imageFile = event.target.files[0];
    if (imageFile.type !== "image/jpeg") {
      setMessageForCover("La imagen debe tener formato JPG/JPEG");
      setLoadingImage(false);
      return;
    }
    img.src = window.URL.createObjectURL(imageFile);
    img.onload = async () => {
      setLoadingImage(true);
      if (imageIsValid(img)) {
        let saveAsThumbnail = true;
        let [errorAddingFile, urlAndFile] = await to(manageAddImageToStorage(saveAsThumbnail, imageFile, dataAlbum.id,
          'covers', 1048576 * 20, setMessageForCover, setUploadProgress, dataAlbum.ownerEmail));
        if (errorAddingFile) {
          setMessageForCover("Ha ocurrido un error, por favor, intente nuevamente.");
          setLoadingImage(false);
          return;
        }
        dispatch(updateAddingAlbumImageUrlAndCoverRedux({ imagenUrl: urlAndFile.url }));
        const cover = {
          resolution_height: img.height,
          resolution_width: img.width,
          original_filename: urlAndFile.file.name,
          original_size: urlAndFile.file.size,
          modified_date: new Date(),
        };
        let editResult = await toWithOutError(dispatch(albumsEditRedux(dataAlbum, { imagenUrl: urlAndFile.url, cover }, dataAlbum.ownerEmail, false, {})));
        if (editResult === "ERROR") { setLoadingImage(false); return; }
        let fugaResult = await toWithOutError(dispatch(albumsUploadCoverRedux(dataAlbum.fugaId, urlAndFile.file, fugaCoverImageId, dataAlbum.ownerEmail)));
        if (fugaResult === "SUCCESS") dispatch(basicSuccess(`Imagen editada correctamente: ${dataAlbum.title}`, false));
        onImageUploadSuccess();
        setLoadingImage(false);
      }
      else {
        setMessageForCover("La imagen debe tener una resolución cuadrada y estar entre 3000x3000 y 6000x6000.");
        setLoadingImage(false);
      }
    };
  };

  return (
    <Card sx={{ borderRadius: '30px', width: '20em', height: '20em', margin: '5px' }}>

      <Grid item sx={{ height: '20em', position: 'relative' }}>
        {/* Contenedor con position: relative para posicionar el Backdrop */}
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <Backdrop
            open={loadingImage}
            sx={{
              position: 'absolute',
              zIndex: 1,
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <CircularProgress />
          </Backdrop>

          {isEditing ? (
            <ImageInput
              key="edit-image-album"
              imagenUrl={dataAlbum.imagenUrl || imageAsUrlData}
              onClickAddImage={onClickEditImage}
              textButton={"Cambiar Arte de Tapa"}
              progress={uploadProgress}
              message={messageForCover}
              fileTypesAccept={"image/jpeg"}
              fileType="image/jpeg"
              helperText={albumCoverHelperText}
              sxButton={{ backgroundColor: "#508062", '&:hover': { backgroundColor: "#508062" } }}
              noFab={true}
            />
          ) : (
            <ButtonBase sx={{ width: '320px', height: '100%' }}>
              {dataAlbum.imagenUrl || imageAsUrlData ? (
                <ImageView
                  style={{ width: '100%', height: '100%', borderRadius: '2em' }}
                  alt="album-image"
                  src={imageAsUrlData || dataAlbum.imagenUrl}
                  duration={30}
                />
              ) : (
                <Skeleton sx={{ borderRadius: '20px' }} animation="wave" variant="rectangular" width={320} height={320} />
              )}
            </ButtonBase>
          )}

        </div>
      </Grid>
    </Card>
  );
};

export default ImageInputAlbum;
