// PAYOUTS METHODS
import { Link } from '@mui/material';
export const DEPOSITO = "DEPOSITO";
export const CUPON = "CUPON";
export const PAYPAL = "PAYPAL";
export const PAYONEER = "PAYONEER";
export const CRIPTO = "CRIPTO";
export const WISE = "WISE";
export const MULTA = "MULTA";

export const payoutPayMethods = [DEPOSITO, PAYPAL, PAYONEER, CUPON, CRIPTO, WISE, MULTA];
export const payoutPayMethodToShow = ["Depósito", "PayPal", "Payoneer", "Cupón", "Cripto", "Wise", "Multa"];

// Subscriptions Payment Method
export const SUB_BANK = "SUB_BANK";
export const SUB_CUPON = "SUB_CUPON";
export const SUB_AUTO_RENEW = "SUB_AUTO_RENEW";
export const SUB_MP = "SUB_MP";
export const SUB_PAYPAL = "SUB_PAYPAL";
export const SUB_PAYONEER = "SUB_PAYONEER";
export const SUB_CRIPTO = "SUB_CRIPTO";
export const SUB_WESTERN_UNION = "SUB_WESTERN_UNION";
export const SUB_WISE = "SUB_WISE";
export const SUB_AIRTM = "SUB_AIRTM";
export const SUB_OTHER = "SUB_OTHER";

export const paymentMethodsUsd = [SUB_CRIPTO, SUB_PAYONEER, SUB_PAYPAL, SUB_WESTERN_UNION, SUB_WISE];

export const paypalComission = 0.054;

export const subIdToSubString = {
  SUB_AIRTM: "AirTM",
  SUB_BANK: "Transferencia",
  SUB_CRIPTO: "Cripto",
  SUB_MP: "Mercado Pago",
  SUB_CUPON: "Cupón de descuento",
  SUB_AUTO_RENEW: "Cupon: Automático",
  SUB_WESTERN_UNION: "Western Union",
  SUB_PAYPAL: "PayPal",
  SUB_PAYONEER: "Payoneer",
  SUB_OTHER: "Otro",
  SUB_MP_PENDING: "MP Pendiente",
  SUB_WISE: "Wise USD"
}

export const paymentsMethodsListToSelect = [
  { id: SUB_AIRTM, name: "AirTM" },
  { id: SUB_BANK, name: "Transferencia" },
  { id: SUB_CRIPTO, name: "Cripto" },
  { id: SUB_MP, name: "Mercado Pago" },
  { id: SUB_CUPON, name: "Cupón de descuento" },
  { id: SUB_AUTO_RENEW, name: "Cupon: Automático" },
  { id: SUB_WESTERN_UNION, name: "Western Union" },
  { id: SUB_PAYPAL, name: "PayPal" },
  { id: SUB_PAYONEER, name: "Payoneer" },
  { id: SUB_OTHER, name: "Otro" },
  { id: SUB_WISE, name: "Wise USD" }
]

export const paymentsFilterMethodsValues = [
  'Transferencia', 'Cripto', 'Mercado Pago', 'PayPal', 'Payoneer',
  'Wise USD', 'Cupón de descuento', 'Cupon: Automático', 'Western Union', 'Otro'
]

const otherOptionArsPayString = "Transferencia Directa: ARS desde cualquier CBU/CVU"
const otherOptionUsdPayString = "USD → PayPal, Wise, Western Union, Criptomonedas"

export const payOptionInChooseReview = [
  { value: 'mp', subValues: [SUB_MP], label: 'Mercado Pago', gridSize: 6 },
  { value: 'other-ars', subValues: [SUB_BANK], label: otherOptionArsPayString, gridSize: 12 },
  { value: 'other-usd', subValues: [SUB_CRIPTO, SUB_PAYONEER, SUB_PAYPAL, SUB_AIRTM, SUB_WISE], label: otherOptionUsdPayString, gridSize: 12 }
]

export const subPaymentMethodToString = (subId, noNeedToPay) => {
  if (noNeedToPay) return "Cupón";
  if (!subId || subId === "Sin Info") return "Sin Info";
  if (subId === "-") return "Sin Pago";
  return subIdToSubString[subId];
}

// Mercado Pago 
export const MP_URL_CHECKOUT = "https://us-central1-laflota-dev.cloudfunctions.net/mpPaymentsV2-server/api/checkout";

export const SUB_MP_CANCELLED = "SUB_MP_CANCELLED";
export const SUB_MP_REJECTED = "SUB_MP_REJECTED";
export const SUB_MP_IN_PROCESS = "SUB_MP_IN_PROCESS";
export const SUB_MP_PENDING = "SUB_MP_PENDING";
export const SUB_MP_APPROVED = "SUB_MP_APPROVED";
export const SUB_MP_AUTHORIZED = "SUB_MP_AUTHORIZED";

export const notSuccessfullMpStates = [SUB_MP_CANCELLED, SUB_MP_REJECTED, SUB_MP_IN_PROCESS, SUB_MP_PENDING];
export const succesfullMpStates = [SUB_MP_APPROVED, SUB_MP_AUTHORIZED];

// Preference Status
export const SUB_PREF_CREATED = "preference_created";
export const SUB_PREF_REJECTED = "preference_rejected";
export const SUB_PREF_REQUESTED = "preference_requested";
export const SUB_PREF_PENDING_PAY = "preference_pending_pay";
export const SUB_PREF_USER_CANCELLED = "sub_user_cancelled";

// All Payments Variables
export const SUB_ORDER_CANCELLED_BY_USER = "SUB_ORDER_CANCELLED_BY_USER";
export const SUB_ORDER_NEED_REFUND = "SUB_ORDER_NEED_REFUND";
export const SUB_ORDER_REFUNDED = "SUB_ORDER_REFUNDED";
export const SUB_ORDER_PENDING_PAY = "SUB_ORDER_PENDING_PAY";
export const SUB_ORDER_PAYED = "SUB_ORDER_PAYED";
export const SUB_ORDER_NEED_CHECK = "SUB_ORDER_NEED_CHECK";
export const SUB_ORDER_REJECTED = "SUB_ORDER_REJECTED";
export const SUB_ORDER_PLAN_SELECTED = "SUB_ORDER_PLAN_SELECTED";
export const SUB_ORDER_NEED_PROOF = "SUB_ORDER_NEED_PROOF";
export const SUB_ORDER_REJECTED_PROOF = "SUB_ORDER_REJECTED_PROOF";
export const allSuccesfullPaymentsStates = [...succesfullMpStates, SUB_ORDER_PAYED, SUB_ORDER_NEED_CHECK]
export const allPendingPayPaymentsStates = [SUB_ORDER_PENDING_PAY, SUB_ORDER_PLAN_SELECTED, SUB_MP_PENDING, SUB_ORDER_NEED_PROOF, SUB_ORDER_REJECTED_PROOF]
export const allStartedPaymentsStates = [...allSuccesfullPaymentsStates, ...allPendingPayPaymentsStates];
export const paymentStatusCouldHadProof = [SUB_ORDER_PAYED, SUB_ORDER_NEED_CHECK, SUB_ORDER_NEED_REFUND, SUB_ORDER_REFUNDED, SUB_ORDER_REJECTED]

export const paymentStatesShowAdmin = {
  SUB_ORDER_CANCELLED_BY_USER: "Cancelado por User",
  SUB_ORDER_PENDING_PAY: "Pendiente de pago",
  SUB_MP_PENDING: "MP Pendiente de Pago",
  SUB_ORDER_PAYED: "Pagado",
  SUB_ORDER_NEED_CHECK: "Esperando Verificación",
  SUB_ORDER_REJECTED: "Rechazada",
  SUB_ORDER_REJECTED_PROOF: "Comprobante rechazado",
  SUB_ORDER_NEED_PROOF: "Esperando comprobante",
  SUB_ORDER_REFUNDED: "Reembolsado",
  SUB_ORDER_NEED_REFUND: "Pide Reembolso",
  SUB_ORDER_PLAN_SELECTED: "Plan seleccionado"
}

export const paymentsFilterStatusValues = [
  "Pagado", "Cancelado por User", "Pendiente de pago", "Esperando Verificación", "Rechazada",
  "Comprobante rechazado", "Esperando comprobante", "Reembolsado", "Pide Reembolso", "Plan seleccionado"
]

export const paymentStatesListShowAdmin = [
  { id: SUB_ORDER_CANCELLED_BY_USER, name: paymentStatesShowAdmin[SUB_ORDER_CANCELLED_BY_USER] },
  { id: SUB_ORDER_PENDING_PAY, name: paymentStatesShowAdmin[SUB_ORDER_PENDING_PAY] },
  { id: SUB_MP_PENDING, name: paymentStatesShowAdmin[SUB_MP_PENDING] },
  { id: SUB_ORDER_PAYED, name: paymentStatesShowAdmin[SUB_ORDER_PAYED] },
  { id: SUB_ORDER_NEED_CHECK, name: paymentStatesShowAdmin[SUB_ORDER_NEED_CHECK] },
  { id: SUB_ORDER_REJECTED, name: paymentStatesShowAdmin[SUB_ORDER_REJECTED] },
  { id: SUB_ORDER_REJECTED_PROOF, name: paymentStatesShowAdmin[SUB_ORDER_REJECTED_PROOF] },
  { id: SUB_ORDER_NEED_PROOF, name: paymentStatesShowAdmin[SUB_ORDER_NEED_PROOF] },
  { id: SUB_ORDER_NEED_REFUND, name: paymentStatesShowAdmin[SUB_ORDER_NEED_REFUND] },
  { id: SUB_ORDER_REFUNDED, name: paymentStatesShowAdmin[SUB_ORDER_REFUNDED] },
  { id: SUB_ORDER_PLAN_SELECTED, name: paymentStatesShowAdmin[SUB_ORDER_PLAN_SELECTED] }
]

export const paymentStateColors = {
  SUB_ORDER_NEED_CHECK: "rgb(231, 190, 66)",
  SUB_ORDER_NEED_PROOF: "rgb(231, 190, 66)",
  SUB_ORDER_REJECTED_PROOF: "rgb(231, 190, 66)",
  SUB_ORDER_PENDING_PAY: "rgb(231, 190, 66)",
  SUB_MP_PENDING: "rgb(231, 190, 66)",
  USER_NEED_RENOVATION: "rgb(111, 2, 2)",
  SUB_ORDER_NEED_REFUND: "rgb(111, 2, 2)",
  SUB_ORDER_REFUNDED: "red",
  SUB_ORDER_PAYED: "rgb(93, 109, 15)",
  SUB_ORDER_REJECTED: "red",
  SUB_ORDER_PLAN_SELECTED: "black"
}

// PLANES

//Viejos
export const PLAN_ALBUM_PERM_1 = "ALBUM_PERMANENTE_X1";
export const PLAN_ALBUM_PERM_2 = "ALBUM_PERMANENTE_X2";

export const PLAN_CHARLY_GARCIA_1 = "charly-garcia";
export const PLAN_FITO_PAEZ_2 = "fito-paez:dos-en-la-ciudad";
export const PLAN_CERATI_3 = "gustavo-cerati:te-para-tres";
export const PLAN_SPINETTALANDIA_5 = "spinettalandia-5";
export const PLAN_SPINETTALANDIA_10 = "spinettalandia-10";
export const PLAN_SPINETTALANDIA_15 = "spinettalandia-15";
export const PLAN_SPINETTALANDIA_20 = "spinettalandia-20";
export const PLAN_SPINETTALANDIA_50 = "spinettalandia-50";
export const PLAN_SPINETTALANDIA_100 = "spinettalandia-100";

export const payoutPayMethodIdToView = {
  DEPOSITO: "Depósito",
  DEPOSITO_USD: "Depósito USD",
  CUPON: "Cupón",
  PAYPAL: "PayPal",
  PAYONEER: "Payoneer",
  CRIPTO: "Cripto",
  WISE: "Wise",
  MULTA: "Multa"
}

export const mediosDePagoWithInfo = [
  { id: 'bank', idDB: DEPOSITO, label: "Transferencia a CBU/CVU", currency: 'ars', checkBoxHelper: "Comisión: 0%" },
  { id: 'cupon', idDB: CUPON, label: "Utilizar regalías como cupón de crédito", currency: 'ars', checkBoxHelper: "Comisión: 0%" },
  { id: 'paypal', idDB: PAYPAL, label: "PayPal", currency: 'usd', checkBoxHelper: "Comisión: 5,4% + 0,3 USD" },
  { id: 'wise', idDB: WISE, label: "Wise", currency: "usd", checkBoxHelper: "Comisión: 5%" }
  // { id: 'payoneer', idDB: PAYONEER, label: "Payoneer", currency: 'usd', checkBoxHelper: `Comisión: 5% \nEl mínimo debe ser 52,63 USD, ya que la plataforma tiene un mínimo de envío de 50 USD.` }
];

export const planesLaFlota = [
  { id: PLAN_CHARLY_GARCIA_1, priceUsd: 28, name: "Charly García - 1", title: "Charly García", subtitle: 'No soy un extraño', maxArtists: 1, imgSource: "/images/charly-garcia.jpg", },
  { id: PLAN_FITO_PAEZ_2, priceUsd: 50, name: "Fito Páez - 2", title: "Fito Páez", subtitle: 'Dos en la ciudad', maxArtists: 2, imgSource: "/images/fito-paez:dos-en-la-ciudad.jpg", },
  { id: PLAN_CERATI_3, priceUsd: 70, name: "Gustavo Cerati - 3", title: "Gustavo Cerati", subtitle: 'Té para tres', maxArtists: 3, imgSource: "/images/gustavo-cerati:te-para-tres.jpg", },
  { id: PLAN_SPINETTALANDIA_5, priceUsd: 119, name: "Spinettalandia - 5", title: "Spinettalandia", subtitle: 'Jardin de Gente', maxArtists: 5, imgSource: "/images/spinettalandia.jpg", },
  { id: PLAN_SPINETTALANDIA_10, priceUsd: 219, name: "Spinettalandia - 10", title: "Spinettalandia", subtitle: 'Jardin de Gente', maxArtists: 10, imgSource: "/images/spinettalandia.jpg", },
  { id: PLAN_SPINETTALANDIA_15, priceUsd: 299, name: "Spinettalandia - 15", title: "Spinettalandia", subtitle: 'Jardin de Gente', maxArtists: 15, imgSource: "/images/spinettalandia.jpg", },
  { id: PLAN_SPINETTALANDIA_20, priceUsd: 399, name: "Spinettalandia - 20", title: "Spinettalandia", subtitle: 'Jardin de Gente', maxArtists: 20, imgSource: "/images/spinettalandia.jpg", },
  { id: PLAN_SPINETTALANDIA_50, priceUsd: 799, name: "Spinettalandia - 50", title: "Spinettalandia", subtitle: 'Jardin de Gente', maxArtists: 50, imgSource: "/images/spinettalandia.jpg", },
  { id: PLAN_SPINETTALANDIA_100, priceUsd: 1499, name: "Spinettalandia - 100", title: "Spinettalandia", subtitle: 'Jardin de Gente', maxArtists: 100, imgSource: "/images/spinettalandia.jpg", },
  { id: PLAN_ALBUM_PERM_1, name: "Plan Hibernado · X1", title: "Plan Hibernado · X1", maxArtists: 1, imgSource: "/images/charly-garcia.jpg", },
  { id: PLAN_ALBUM_PERM_2, name: "Plan Hibernado · X2", title: "Plan Hibernado · X2", maxArtists: 1, imgSource: "/images/charly-garcia.jpg", },
]

export const planIdToCantArtists = {
  PLAN_CHARLY_GARCIA_1: 1,
  PLAN_FITO_PAEZ_2: 2,
  PLAN_CERATI_3: 3,
  PLAN_SPINETTALANDIA_5: 5,
  PLAN_SPINETTALANDIA_10: 10,
  PLAN_SPINETTALANDIA_15: 15,
  PLAN_SPINETTALANDIA_20: 20,
  PLAN_SPINETTALANDIA_50: 50,
  PLAN_SPINETTALANDIA_100: 100
}

export const mpPayImage = "https://firebasestorage.googleapis.com/v0/b/laflota-dev.appspot.com/o/generalIcons%2Fmercadopago-checkout-radio.png?alt=media&token=a3778391-c977-4a27-9ea2-2b00f2b62562";
export const mpCVU = "0000003100048627996731";
export const mpAlias = "laflota.mp";

export const ourPaypalEmail = "paypal@laflota.com.ar";
export const ourPayoneerEmail = "naailujog@proton.me";
export const ourWesternUnionData = { nombre: "Julián Olaso", ciudad: "Viedma, Río Negro, Argentina", dni: "34.958.715" };
export const ourCriptoInfo = {
  coinsAcceptedText: "BTC (Bitcoin), ETH (Ethereum), USDT (TetherUS), BNB (BNB),\n SOL (Solana), ADA (Cardano)",
  red: "BSC BNB Smart Chain (BEP 20)", direccion: "0x89a864f9cd5eec959a9cd40134650b7cf63c67a5"
}

export const ourWiseInfo = <>
  <b style={{ fontSize: "16px", fontWeight: 450, mb: "8px" }}>Wise USD</b>
  <br />
  <b>Utiliza estos datos para añadir o recibir USD desde cuentas bancarias dentro de los EE. UU.:</b>
  <br />
  <b>Titular de la cuenta: Julián Olaso</b>
  <br />
  <b>Email: naailujog@proton.me</b>
  <br />
  <b>Número de ruta ACH y wire: 026073150</b>
  <br />
  <b>Número de cuenta: 8312385771</b>
  <br />
  <b>Tipo de cuenta: Corriente</b>
  <br />
  <b>Dirección de Wise: 30 W. 26th Street, Sixth Floor New York NY 10010 United States</b>
  <br />
  <br />
  <b>Utiliza estos datos para añadir o recibir USD desde cuentas bancarias desde fuera de los EE. UU.:</b>
  <br />
  <b>Titular de la cuenta: Julián Olaso</b>
  <br />
  <b>Número de ruta: 026073150</b>
  <br />
  <b>SWIFT/BIC: CMFGUS33</b>
  <br />
  <b>Número de cuenta: 8312385771</b>
  <br />
  <b>Dirección de Wise: 30 W. 26th Street, Sixth Floor New York NY 10010 United States</b>
</>



// Wise €
// Utiliza estos datos para añadir o recibir EUR desde cuentas bancarias dentro de UE y SEPA:
// Titular de la cuenta: Julián Olaso
// Email: naailujog@proton.me
// BIC: TRWIBEB1XXX
// IBAN: BE25 9674 2233 0282
// Dirección de Wise: Avenue Louise 54, Room S52, Brussels,1050 Belgium

// Utiliza estos datos para añadir o recibir EUR desde cuentas bancarias fuera de UE y SEPA:
// Titular de la cuenta: Julián Olaso
// SWIFT/BIC: TRWIBEB1XXX
// IBAN: BE25 9674 2233 0282
// Dirección de Wise: Avenue Louise 54, Room S52, Brussels,1050 Belgium

export const termsAndConditionsLink = <Link href={"https://www.laflota.com.ar/terminos-y-condiciones/"} sx={{ color: "black" }} target="_blank" variant="body2" underline="none">
  {"términos y condiciones"}
</Link>